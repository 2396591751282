import { AppProps } from 'next/app';
import Head from 'next/head';
import { AppleSplashes } from './AppleSplashes';
import { appTitle, backdropColor, description, googleAnalytics, iosStatusBarStyle, webTitle, webUrl } from './config';
import { ModelProvider } from './model';
import { PageProvider } from './page';
import { Root } from './Root';
import { ScrollRestoreProvider } from './scrollRestore';

export const App = (props: AppProps) => (
  <>
    <Head>
      <title>{webTitle}</title>
      <meta name='description' content={description} />
      <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no' />
      <meta name='format-detection' content='telephone=no' />
      <meta name='mobile-web-app-capable' content='yes' />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content={iosStatusBarStyle} />
      <meta name='apple-mobile-web-app-title' content={appTitle} />
      <meta name='theme-color' content={backdropColor} />
      <link rel='icon' href='/icon-rounded.svg' />
      <link rel='apple-touch-icon' href='/icon-ios.png' />
      <link rel='manifest' href='/manifest.json' />

      <meta key='og:type'               property='og:type'          content='website' />
      <meta key='og:locale'             property='og:locale'        content='en_US' />
      <meta key='og:url'                property='og:url'           content={`${webUrl}${props.router.pathname}`} />
      <meta key='og:site_name'          property='og:site_name'     content={webTitle} />
      <meta key='og:title'              property='og:title'         content={webTitle} />
      <meta key='og:description'        property='og:description'   content={description} />
      <meta key='og:image'              property='og:image'         content={`${webUrl}/card.png`} />
      <meta key='og:image:width'        property='og:image:width'   content='1200' />
      <meta key='og:image:height'       property='og:image:height'  content='630' />
      <meta key='twitter:title'         name='twitter:title'        content={webTitle} />
      <meta key='twitter:description'   name='twitter:description'  content={description} />
      <meta key='twitter:card'          name='twitter:card'         content='summary_large_image' />
      <meta key='twitter:image:width'   name='twitter:image:width'  content='1200' />
      <meta key='twitter:image:height'  name='twitter:image:height' content='630' />
      <meta key='twitter:image'         name='twitter:image'        content={`${webUrl}/card.png`} />

      {process.env.NODE_ENV !== 'development' && (
        <>
          <link rel='preconnect' href='https://www.googletagmanager.com' />
          <script defer src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalytics}`} />
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${googleAnalytics}');
              `
            }}
          />
        </>
      )}
    </Head>
    <AppleSplashes />
    <PageProvider {...props}>
      <ModelProvider data={props.pageProps}>
        <ScrollRestoreProvider>
          <Root />
        </ScrollRestoreProvider>
      </ModelProvider>
    </PageProvider>
  </>
);
