import { faCheck, faExclamationTriangle, faInfo, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTimeout } from '../hooks/useTimeout';
import { maxUndoWaitMs, Toast as Data, ToastDuration, ToastStyle, useModel } from '../model';

enum Status {
  Hidden,
  Show,
  Visible,
  Hide,
  HideFast
}

export const Toast = () => {
  const model = useModel();
  const timeout = useTimeout();
  const toastRef = useRef<HTMLDivElement>(null);
  const [ state, setState ] = useState<{
    status: Status
    current?: Data
    next?: Data
  }>({
    status: Status.Hidden
  });
  const stateRef = useRef(state);
  stateRef.current = state;

  const hideFast = () => {
    timeout.clear('visible');
    setState(current => ({ ...current, status: Status.HideFast }));
  };

  useEffect(() => {
    if (model.toast) {
      if (stateRef.current.status === Status.Hidden) {
        setState({ current: model.toast, status: Status.Show });
      } else {
        timeout.clear('visible');
        setState(current => ({ ...current, next: model.toast, status: Status.HideFast }));
      }
    } else if (stateRef.current.status !== Status.Hidden) {
      timeout.clear('visible');
      setState(current => ({ ...current, status: Status.HideFast }));
    }
  }, [ model.toast ]);

  useEffect(() => {
    const onTransitionEnd = (event: TransitionEvent) => {
      if (toastRef.current == event.target && event.propertyName === 'transform') {
        switch (state.status) {
          case Status.Show:
            if (state.next) {
              hideFast();
            } else {
              setState(current => ({ ...current, status: Status.Visible }));

              timeout.set('visible', {
                [ToastDuration.Short]: maxUndoWaitMs / 4,
                [ToastDuration.Standard]: maxUndoWaitMs / 2,
                [ToastDuration.Long]: maxUndoWaitMs,
              }[state.current!.duration], () => {
                setState(current => ({ ...current, status: Status.Hide }));
              });
            }

            break;
          case Status.Hide:
          case Status.HideFast:
            if (state.next) {
              setState(({ current: state.next, status: Status.Show }));
            } else {
              setState(({ status: Status.Hidden }));
            }

            break;
          default:
        }
      }
    };

    toastRef.current!.addEventListener('transitionend', onTransitionEnd);

    return () => {
      if (toastRef.current) {
        toastRef.current.removeEventListener('transitionend', onTransitionEnd);
      }
    };
  }, [ state ]);

  return (
    <div className={cn(
      '[--height:_calc(var(--unit)_*_10)]',
      'z-toast flex justify-center items-center w-full',
      'fixed top-[calc(var(--header-height)_-_var(--height))]',
      'select-none pointer-events-none'
    )}>
      <div
        ref={toastRef}
        className={cn(
          'flex items-center gap-2.5 px-5 min-h-[var(--height)] rounded-full',
          'text-2sm text-fg bg-floating backdrop-blur-floating backdrop-saturate-floating shadow-floating', {
            'pl-4': !state.current?.undo,
            'transition duration-400 pointer-events-auto': state.status !== Status.Hidden,
            'opacity-0 scale-80 -translate-y-1.5': state.status === Status.Hide || state.status === Status.Hidden,
            'opacity-1 scale-100 duration-400 ease-[cubic-bezier(0.34,1.56,0.64,1)]': state.status === Status.Show || state.status === Status.Visible,
            'ease-[cubic-bezier(0.36,0,0.66,-0.56)]': state.status === Status.Hide,
            'opacity-0 scale-95 duration-200 ease-linear': state.status === Status.HideFast
          }
        )}
        onClick={state.status === Status.Visible ? hideFast : undefined}
      >
        {state.current?.style === ToastStyle.Success && <FontAwesomeIcon className='h-2.5 text-success' icon={faCheck} />}
        {state.current?.style === ToastStyle.Info && <FontAwesomeIcon className='h-2.5 text-info' icon={faInfo} />}
        {state.current?.style === ToastStyle.Error && <FontAwesomeIcon className='h-2.5 text-error' icon={faExclamationTriangle} />}
        <div className={cn({ 'mr-1.5': state.current?.undo })}>{state.current?.message}</div>
        {state.current?.undo && (
          <>
            <div className='w-border h-[var(--height)] bg-border' />
            <button
              className={cn(
                'flex items-center h-[var(--height)] gap-1 pl-4 pr-5 -ml-2.5 -mr-5.25',
                'text-2sm font-semibold',
                'active:scale-90 transition duration-150'
              )}
              disabled={state.status !== Status.Visible}
              onClick={() => {
                state.current!.undo!();
                hideFast();
              }}
            >
              <FontAwesomeIcon className='h-2.5 fill-gradientSvg' icon={faUndo} />
              <div className='text-gradient'>Undo</div>
            </button>
          </>
        )}
      </div>
    </div>
  );
};
