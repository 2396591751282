import cn from 'classnames';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useRuntimeEnv } from '../hooks/useRuntimeEnv';
import { ToastDuration, ToastStyle, useModel } from '../model';
import { usePage } from '../page';
import { Footer } from './Footer';
import { Article } from './pages/Article';
import { Calendar } from './pages/Calendar';
import { Day } from './pages/Day';
import { Error } from './pages/Error';
import { Ideas } from './pages/Ideas';
import { Notes } from './pages/Notes';
import { Post } from './pages/Post';
import { Top } from './pages/Top';
import { ServerStatus } from './ServerStatus';
import { Toast } from './Toast';
import { WaitingOverlay } from './WaitingOverlay';
import { Welcome } from './Welcome';

export const Root = () => {
  const router = useRouter();
  const model = useModel();
  const page = usePage();
  const runtimeEnv = useRuntimeEnv();
  const path = router.pathname;

  useEffect(() => {
    if (router.query.login === 'error') {
      model.showToast('Oops! Something went wrong… 😓', ToastStyle.Error, ToastDuration.Standard);
    }

    if (router.query.login === 'success') {
      model.showToast(`Hey, ${model.data.user!.firstName}! 👋`, ToastStyle.Success, ToastDuration.Standard);
    }

    if (router.query.login) {
      router.replace('/', undefined, { scroll: false, shallow: true });
    }
  }, []);

  useEffect(() => {
    if (model.dragging) {
      if (!document.documentElement.classList.contains('dragging')) {
        document.documentElement.classList.add('dragging');
      }
    } else {
      if (document.documentElement.classList.contains('dragging')) {
        document.documentElement.classList.remove('dragging');
      }
    }
  }, [ model.dragging ]);

  return (
    <div className={cn(
      '[--header-height:_calc(var(--unit)_*_11)]',
      '[--footer-bundle-copied-height:_0px]',
      '[--footer-menu-height:_calc(var(--unit)_*_15)]',
      '[--safe-bottom-inset:_env(safe-area-inset-bottom)]',
      '[--footer-height:_calc(var(--footer-bundle-copied-height)_+_var(--footer-menu-height)_+_var(--safe-bottom-inset))]',
      {
        '[--content-offset:_0px]': runtimeEnv.touch,
        '[--footer-bundle-copied-height:_calc(var(--unit)_*_5)]': model.copiedBundle,
        'iphone-home-bar:[--safe-bottom-inset:_calc(var(--unit)_*_8)]': runtimeEnv.iphone
      })}
    >
      <svg width={0} height={0}>
        <defs>
          <linearGradient id='gradient' x1='40%' y1='150%' x2='75%' y2='-50%'>
            <stop stopColor='var(--accent)' offset='-29.75%' />
            <stop stopColor='var(--accent2)' offset='134.73%' />
          </linearGradient>
        </defs>
      </svg>
      <WaitingOverlay />
      <Welcome />
      <div className={cn('flex flex-col items-center w-full min-h-screen pt-[var(--header-height)] pb-[var(--footer-height)] bg-bg text-fg', {
        'browser:min-h-screen-dvh': runtimeEnv.iphone154
      })}>
        <ServerStatus />
        <Toast />
        <main className='flex flex-col flex-1 w-full max-w-content'>
          {path === '/404' && <Error notFound />}
          {path === '/500' && <Error />}
          {[
            '/',
            '/top/[productKey]',
            '/top/bundles/[bundleKey]',
          ].includes(path) && <Calendar />}
          {[
            '/day/[date]',
            '/top/add/[date]',
            '/top/[productKey]/day/[date]',
            '/top/bundles/add/[date]',
            '/top/bundles/[bundleKey]/day/[date]'
          ].includes(path) && <Day />}
          {[
            '/top',
            '/top/bundles'
          ].includes(path) && <Top />}
          {path === '/notes' && <Notes />}
          {path === '/ideas' && <Ideas />}
          {[
            '/privacy-policy',
            '/terms-of-service'
          ].includes(path) && <Article />}
          {page.created && <Post />}
        </main>
        <Footer />
      </div>
    </div>
  );
};
