import cn from 'classnames';
import { useRef } from 'react';
import { useForceRenderOnScroll } from '../../hooks/useForceRenderOnScroll';
import { useRuntimeEnv } from '../../hooks/useRuntimeEnv';
import { usePage } from '../../page';
import { Header } from '../Header';
import styles from './styles.module.css';

export const Article = () => {
  useForceRenderOnScroll();
  const page = usePage();
  const runtimeEnv = useRuntimeEnv();
  const headingRef = useRef<HTMLHeadingElement>(null);
  const headerTitleVisibility = runtimeEnv.browser && headingRef.current ?
    Math.min(1,
      Math.max(
        0, document.documentElement.scrollTop - headingRef.current.getBoundingClientRect().top - headingRef.current.getBoundingClientRect().height / 4
      ) / headingRef.current.getBoundingClientRect().height
    ) : 0;

  return (
    <div className='p-5'>
      <Header>
        <div className='font-semibold truncate' style={{
          transform: `translateY(${(1 - headerTitleVisibility) * 10}px)`,
          opacity: headerTitleVisibility
        }}>
          {page.title}
        </div>
      </Header>
      <h1 ref={headingRef} className='text-4lg font-bold mb-10'>{page.title}</h1>
      <div className={cn(styles.textPageContent, 'flex flex-col gap-5 mb-15')}>
        <page.Component />
      </div>
    </div>
  );
};
