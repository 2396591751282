import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { ModelStatus, useModel } from '../model';

export const ServerStatus = () => {
  const model = useModel();
  const [ status, setStatus ] = useState(ModelStatus.Idle);

  useEffect(() => {
    setStatus(model.status);
  }, [ model.status ]);

  return (
    <>
      <div className={cn(
        'z-serverStatus',
        "fixed top-1.75 left-[calc(var(--content-offset)_+_var(--unit)_*_1.75)]",
        'transition duration-500 ease-linear', {
          'opacity-0': status !== ModelStatus.Syncing
        }
      )}>
        <div className='h-1 aspect-square rounded-full bg-gradient animate-pulse' />
      </div>
      <div className={cn(
        'z-serverStatus',
        'fixed top-1 left-[calc(var(--content-offset)_+_var(--unit))]',
        'transition duration-500 ease-linear', {
          'opacity-0': status !== ModelStatus.Offline
        }
      )}>
        <FontAwesomeIcon className='h-2 aspect-square text-fg' icon={faBolt} />
      </div>
    </>
  );
};
