import { NextComponentType } from 'next';
import { AppProps } from 'next/app';
import { createContext, PropsWithChildren, useContext } from 'react';
import { UrlObject } from 'url';
import { Tab } from './tabs';

export interface PageComponentStaticProps {
  tab: Tab
  level: number
  slug: string
  title: string
  description: string
  created: string
  updated: string
  cmsId: number
}

interface Page extends PageComponentStaticProps {
  Component: NextComponentType
  backHref: (pageQueries: string[], options?: { prefixed: boolean }) => UrlObject
  nextHref: (segment: string, queryKey?: string, queryValue?: any) => UrlObject
}

const Context = createContext({} as Page);

export const usePage = () => useContext(Context);

export const PageProvider = (props: PropsWithChildren<AppProps>) => (
  <Context.Provider value={{
    ...(props.Component as any as PageComponentStaticProps),
    Component: props.Component,
    backHref: (pageQueries, options) => {
      const newPathSegments = props.router.pathname.split('/').slice(0, (options?.prefixed ? 2 : 1) * -1);
      const query = { ...props.router.query };

      pageQueries.forEach(param => {
        delete query[param];
      });

      Object.keys(query).forEach(param => {
        if (props.router.pathname.endsWith(`[${param}]`)) {
          delete query[param];
        }
      });

      return {
        pathname: newPathSegments.length === 1 ? '/' : newPathSegments.join('/'),
        query
      };
    },
    nextHref: (nextPath, queryKey, queryValue) => {
      const newPathSegments = (nextPath.startsWith('/') ? nextPath : props.router.pathname).split('/');
      const query = { ...props.router.query };

      if (nextPath && !nextPath.startsWith('/')) {
        newPathSegments.push(nextPath);
      }

      if (queryKey) {
        if (queryValue === undefined) {
          delete query[queryKey];
        } else {
          query[queryKey] = queryValue;
        }
      }

      return {
        pathname: newPathSegments.join('/').replace('//', '/'),
        query
      };
    }
  }}>
    {props.children}
  </Context.Provider>
);
