import { faCalendar, faHeart, faLightbulb, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { UrlObject } from 'url';
import { useRuntimeEnv } from '../hooks/useRuntimeEnv';
import { useModel } from '../model';
import { usePage } from '../page';
import { Tab } from '../tabs';
import { BundleCopiedRow } from './BundleCopiedRow';

const tabs = [
  {
    tab: Tab.Schedule,
    label: 'Schedule',
    path: '/',
    icon: faCalendar
  },
  {
    tab: Tab.Top,
    label: 'Top',
    path: '/top',
    icon: faHeart
  },
  {
    tab: Tab.Notes,
    label: 'Notes',
    path: '/notes',
    icon: faPencilAlt
  },
  /*{
    tab: Tab.Ideas,
    label: 'Ideas',
    path: '/ideas',
    icon: faLightbulb
  }*/
];

export const Footer = () => {
  const page = usePage();
  const tabRef = useRef(page.tab);
  tabRef.current = page.tab;
  const router = useRouter();
  const routerRef = useRef(router);
  routerRef.current = router;
  const model = useModel();
  const runtimeEnv = useRuntimeEnv();

  const [paths, setPaths] = useState({} as Record<Tab, UrlObject>);
  paths[page.tab] = {
    pathname: router.pathname,
    query: router.query
  };

  useEffect(() => {
    const routeChangeStart = () => {
      setPaths(previous => ({
        ...previous,
        [tabRef.current]: {
          pathname: routerRef.current.pathname,
          query: routerRef.current.query
        }
      }));
    };

    router.events.on('routeChangeStart', routeChangeStart);

    return () => router.events.off('routeChangeStart', routeChangeStart);
  }, []);

  return (
    <footer
      className={cn(
        'z-headerFooter select-none overflow-hidden',
        'flex flex-col w-full px-content pb-[var(--safe-bottom-inset)]',
        'fixed bottom-0',
        'border-t border-border bg-floating backdrop-blur-floating backdrop-saturate-floating', {
          'opacity-0 pointer-events-none': runtimeEnv.touch && model.editorFocused
        }
      )}
    >
      <BundleCopiedRow />
      <nav className='flex h-[var(--footer-menu-height)]'>
        {tabs.map(({ tab, label, path, icon }) => (
          <Link legacyBehavior key={tab} href={paths[tab] || path} scroll={false}>
            <a className='flex flex-1 justify-center items-center text-fg-4'>
              <div className='flex flex-col justify-center items-center gap-1.5 h-11'>
                <FontAwesomeIcon className={cn('shrink-0 h-5', {
                  'fill-gradientSvg': tab === page.tab
                })} icon={icon} />
                <div className={cn('text-center leading-none text-3sm w-14', {
                  'font-semibold text-gradient': tab === page.tab
                })}>
                  {label}
                </div>
              </div>
            </a>
          </Link>
        ))}
      </nav>
    </footer>
  );
};
