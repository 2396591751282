import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTimeout } from '../hooks/useTimeout';
import { useModel } from '../model';
import { usePage } from '../page';
import { Tab } from '../tabs';

export const Welcome = () => {
  const router = useRouter();
  const model = useModel();
  const page = usePage();
  const timeout = useTimeout();
  const [ visible, setVisible ] = useState(false);
  const [ appear, setAppear ] = useState(false);

  const onClose = () => {
    setVisible(false);
    model.welcomed();
  };

  useEffect(() => {
    if (![ Tab.None, Tab.Ideas ].includes(page.tab) && !model.data.empty && !model.data.welcomed && !router.query.skipWelcome) {
      setVisible(true);
      timeout.set('appear', 100, () => setAppear(true));
    }
  }, [ page.tab, model.data ]);

  return visible ? (
    <div className='z-welcome flex justify-center items-center fixed inset-0'>
      <div className={cn(
        'fixed inset-0',
        'bg-backdrop opacity-0',
        'transition duration-1000 ease-backdrop', {
          'opacity-[0.45]': appear
        }
      )} onClick={onClose} />
      <div className={cn(
        'flex flex-col items-center gap-10 h-lt-500:gap-5 p-10 h-lt-500:py-5 rounded-3xl max-w-[340px] h-lt-500:max-w-[600px] w-full absolute',
        'bg-floating backdrop-blur-floating backdrop-saturate-floating shadow-floating',
        'translate-y-[100vh]',
        'transition duration-2000 ease-[cubic-bezier(0.3,1.35,0.2,1)]', {
          'translate-y-0': appear
        }
      )}>
        <Image className='h-lt-500:hidden' src='/icon-welcome.png' width='64' height='64' alt='hey.hair icon' />
        <div className='flex flex-col items-center gap-5'>
          <div className='text-fg text-4lg font-bold'>This is <span className='font-black text-gradient'>hey.hair</span></div>
          <div className='text-base text-fg-4 text-center'>
            Your personal Hair Journal App, that helps you keep track of your hair treatment routines.
          </div>
          <div className='text-base text-fg-5'>
            It&apos;s free, by the way!
          </div>
        </div>
        <div className='flex flex-col flex-1 items-center gap-4'>
          <button className={cn(
            'flex items-center px-6 h-12 rounded-full',
            'text-white bg-gradient text-base font-semibold',
            'active:scale-95 transition duration-150'
          )} onClick={onClose}>
            Start Journaling
          </button>
        </div>
      </div>
    </div>
  ) : <></>;
};
