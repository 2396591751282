export { getStaticProps } from '../src/server/ssr';
import { Tab } from '../src/tabs';

const Page = () => (
  <>
    <p>
      Hello and welcome to <span data-branded>hey.hair</span>! 👋
    </p>

    <p>
      We are <strong>Helen</strong> and <strong>Michael</strong> — a hair care enthusiast 💇‍♀️ and a tech guy 🤓.
    </p>

    <p>
      My partner Michael has helped me create the platform of my dreams — a simple and useful hair app.
    </p>

    <h2>Hair problems</h2>

    <p>
      When I came across serious hair problems (like shedding and dryness) in 2018, I searched the web, but have not found a comfortable and simple everyday hair app.
    </p>

    <p>
      I wanted to monitor my progress, note down the products and take notes from the professionals, that I visited.
    </p>

    <h2>First try</h2>

    <p>
      So my solution back then was to use a paper notebook.
    </p>

    <p>
      Soon, however, I became lost in piles of paper and notebooks.
    </p>

    <h2>Second try</h2>

    <p>
      Next thing I tried was the Notes app on my phone.
    </p>

    <p>
      With time it became a mess as well.
      It was really hard to find useful information or the products I liked.
    </p>

    <h2>Third time&apos;s a charm</h2>

    <p>
      My partner saw me struggling, being stuck in notes and offered me to build a platform, that would help me streamline my experience.
    </p>

    <p>
      That is how <span data-branded>hey.hair</span> was born.
    </p>

    <p>
      This is a simple app, that can help you throughout your everyday washing routine. 
    </p>

    <ul>
      <li>Start with the <strong>Wash Day</strong> and fill in the products you&apos;ve used.</li>
      <li>You can <strong>like the products</strong>, that were good and/or <strong>rate the whole bundle</strong>.</li>
      <li>If you like to make some notes on the particular Wash Day — <strong>Add a Comment</strong> from the <code>…</code> menu.</li>
      <li>From the same menu you can also <strong>Add Hairdresser Notes</strong> to make notes of the advice given.</li>
      <li><strong>Copy the top-rated bundle</strong> and use these products again for same spectacular results.</li>
      <li>Mark the leave-in products used during the week and don&apos;t forget to use a scalp serum or hair oil.</li>
    </ul>

    <p>
      <span data-branded>hey.hair</span> app has really helped me make my hair life easier and I hope it can help you with yours as well.
    </p>

    <h2>Got thoughts or questions? 🤔</h2>

    <p>
      I would love to answer your questions and hear from you at <a href='mailto:helen@hey.hair'>helen@hey.hair</a> ✉️
    </p>
  </>
);
Page.slug = '/origin-story';
Page.title = 'The origin story';
Page.description = 'We are Helen and Michael — a hair care enthusiast and a tech guy. My partner Michael has helped me create the platform of my dreams — a simple and useful hair app.';
Page.created = '2022-08-21';
Page.updated = '2022-08-21';
Page.cmsId = 4;
Page.tab = Tab.Ideas;
Page.level = 2;
export default Page;
