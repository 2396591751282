import { faCheck, faRedo, faUndo } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useRef } from 'react';
import { useRuntimeEnv } from '../../hooks/useRuntimeEnv';
import { useModel } from '../../model';
import { Header, HeaderButton } from '../Header';

export const Notes = () => {
  const model = useModel();
  const runtimeEnv = useRuntimeEnv();
  const textArea = useRef<HTMLTextAreaElement>(null);

  const onUndoClick = () => {
    document.execCommand('undo');
    textArea.current!.blur();
  };

  const onRedoClick = () => {
    document.execCommand('redo');
    textArea.current!.blur();
  };

  return (
    <div className='flex flex-col flex-1 relative'>
      <Header
        left={<HeaderButton icon={faUndo} onClick={onUndoClick}>Undo</HeaderButton>}
        right={<HeaderButton icon={faRedo} onClick={onRedoClick}>Redo</HeaderButton>}
      >
        {model.editorFocused && runtimeEnv.touch && <HeaderButton filled icon={faCheck} onClick={() => {}}>Done</HeaderButton>}
      </Header>
      <div className={cn('text-base leading-normal p-5 w-full whitespace-pre-wrap pointer-events-none invisible', {
        'browser:pb-[70%]': runtimeEnv.ios && model.editorFocused
      })}>
        {model.data.notesText}{model.data.notesText === '' || model.data.notesText.endsWith('\n') ? '\n' : ''}
      </div>
      <textarea
        ref={textArea}
        className={cn('text-base leading-normal p-5 w-full min-h-full resize-none bg-bg absolute', {
          'browser:pb-[70%]': runtimeEnv.ios && model.editorFocused
        })}
        placeholder={runtimeEnv.browser && document.activeElement === textArea.current ? 'What\'s on your mind?' : 'A blank page for your hair thoughts…'}
        spellCheck
        value={model.data.notesText}
        onChange={event => model.editNotesText(event.target.value)}
        onFocus={model.focusEditor}
        onBlur={model.blurEditor}
      />
    </div>
  );
};
