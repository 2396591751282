import { useRouter } from 'next/router';
import { createContext, PropsWithChildren, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { browser } from './hooks/useRuntimeEnv';
import { usePage } from './page';

if (browser && window.history.scrollRestoration) {
  window.history.scrollRestoration = 'manual';
}

interface ScrollRestore {
  empty: boolean
  hasForPath: boolean
  skipResetToZero: () => void
}

const Context = createContext({} as ScrollRestore);

export const useScrollRestore = () => {
  const context = useContext(Context);
  useState(context.skipResetToZero);
  return context;
};

export const ScrollRestoreProvider = (props: PropsWithChildren<{}>) => {
  const page = usePage();
  const pageRef = useRef(page);
  pageRef.current = page;
  const router = useRouter();
  const routerRef = useRef(router);
  routerRef.current = router;
  const lastPageProps = useRef(page);
  const scrollTops = useRef<Record<string, number | undefined>>({});
  const skipResetToZero = useRef(false);

  const getScrollTopForPath = () => {
    const path = router.pathname;
    const scrollTop = scrollTops.current[path];

    if (scrollTop && (pageRef.current.tab !== lastPageProps.current.tab || pageRef.current.level < lastPageProps.current.level)) {
      return scrollTop;
    }

    return undefined;
  };

  useEffect(() => {
    const routeChangeStart = () => {
      lastPageProps.current = pageRef.current;
      scrollTops.current[routerRef.current.pathname] = document.documentElement.scrollTop;
    };

    routerRef.current.events.on('routeChangeStart', routeChangeStart);

    return () => routerRef.current.events.off('routeChangeStart', routeChangeStart);
  }, []);

  if (browser) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      const scrollTop = getScrollTopForPath();

      if (scrollTop === undefined) {
        if (!skipResetToZero.current) {
          window.scrollTo({ top: 0 });
        }
      } else {
        window.scrollTo({ top: scrollTop });
      }

      skipResetToZero.current = false;
    }, [ router.pathname ]);
  }

  return (
    <Context.Provider value={{
      empty: Object.keys(scrollTops.current).length === 0,
      hasForPath: getScrollTopForPath() !== undefined,
      skipResetToZero: () => skipResetToZero.current = true
    }}>
      {props.children}
    </Context.Provider>
  );
};
