import Head from 'next/head';

export const AppleSplashes = () => (
  <Head>
    {[
      [320, 568, [2]],
      [375, 667, [2]],
      [375, 812, [3]],
      [414, 896, [2, 3]],
      [360, 780, [3]],
      [390, 844, [3]],
      [414, 736, [3]],
      [428, 926, [3]],
      [768, 1024, [2]],
      [834, 1112, [2]],
      [1024, 1366, [2]]
    ].map(([w, h, resolutions]) => (
      (resolutions as number[]).map(resolution => (
        ['portrait', 'landscape'].map(orientation => (
          <link key={`${w}.${h}.${resolution}.${orientation}`} rel='apple-touch-startup-image'
            media={[
              `(device-width: ${w}px)`,
              `(device-height: ${h}px)`,
              `(-webkit-device-pixel-ratio: ${resolution})`,
              `(orientation: ${orientation})`
            ].join(' and ')}
            {...([`/splash/${w}x${h}@${resolution}x-${orientation}.png`].reduce((_, v) => ({ key: v, href: v }), {}))} />
        ))
      ))
    ))}
  </Head>
);
