import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isToday, isTomorrow, isYesterday } from 'date-fns';
import uniq from 'lodash/uniq';
import { useModel } from '../model';
import { CategoryDot } from './CategoryDot';

export const BundleCopiedRow = () => {
  const model = useModel();

  return model.copiedBundle ? (
    <div className='flex -mx-content h-[var(--footer-bundle-copied-height)] bg-bg-2 dark:bg-transparent text-fg-5'>
      <div className='flex flex-1 items-center' />
      <div className='flex items-center gap-2.5 uppercase tracking-widest text-4sm font-semibold leading-none'>
        <div>
          Copied {model.copiedBundle.products.length} products from
          {' '}
          {(() => {
            const origin = model.copiedBundle.origin;

            if (origin) {
              return origin;
            }

            const date = model.copiedBundle.date!;

            if (isToday(date)) {
              return 'Today';
            }

            if (isYesterday(date)) {
              return 'Yesterday';
            }

            if (isTomorrow(date)) {
              return 'Tomorrow';
            }

            return 'Another Wash';
          })()}
        </div>
        <div className='flex gap-0.75'>
          {uniq(model.copiedBundle.products.map(({ categoryId }) => categoryId)).map(categoryId => <CategoryDot key={categoryId} id={categoryId} small />)}
        </div>
      </div>
      <div className='flex flex-1 items-center justify-end'>
        <button title='Clear Copied Products' className='h-full pl-5 pr-content' onClick={model.clearCopiedBundle}>
          <FontAwesomeIcon className='h-2.5' icon={faTimes} />
        </button>
      </div>
    </div>
  ) : <></>;
};
