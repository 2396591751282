import { useEffect, useRef } from 'react';

export const useTimeout = () => {
  const timeoutIds = useRef<{ [id: string]: number }>({});

  useEffect(() => () => Object.keys(timeoutIds.current).forEach(clear), []);

  const clear = (id: string) => {
    clearTimeout(timeoutIds.current[id]);
  };

  return {
    clear,
    set: (id: string, delay: number, callback: () => void) => {
      clear(id);

      timeoutIds.current[id] = window.setTimeout(() => {
        callback();
        delete timeoutIds.current[id];
      }, delay);
    }
  };
};
