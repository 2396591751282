import cn from 'classnames';

interface Props {
  id: number
  small?: boolean
}

export const CategoryDot = (props: Props) => (
  <div className={cn(props.small ? 'h-1' : 'h-2', 'aspect-square rounded-full', {
    'bg-category-1': props.id === 1,
    'bg-category-2': props.id === 2,
    'bg-category-3': props.id === 3,
    'bg-category-4': props.id === 4,
    'bg-category-5': props.id === 5,
    'bg-category-6': props.id === 6,
    'bg-category-7': props.id === 7,
    'bg-category-8': props.id === 8,
  })} />
);
