export { getStaticProps } from '../src/server/ssr';
import { Tab } from '../src/tabs';

const Page = () => (
  <>
    <p>Did you know, that you can add <span data-branded>hey.hair</span> to your home screen? 😯</p>

    <p>It&apos;s super-easy to do! 🤓</p>

    <h2>Let me show you how:</h2>

    <ul data-ios>
      <li>Tap the middle icon at the very bottom of the screen. The one with an arrow pointing up.</li>
      <li>Scroll down in the sheet until you find the <strong>Add to Home Screen</strong> button.</li>
      <li>Tap <strong>Add to Home Screen</strong>, then <strong>Add</strong>.</li>
      <li>That&apos;s it!</li>
    </ul>

    <ul data-android>
      <li>Tap the three dots icon in the top right corner.</li>
      <li>Tap <strong>Add to Home screen</strong>, then <strong>Add</strong>.</li>
      <li>That&apos;s it!</li>
    </ul>

    <p>Enjoy your new <span data-branded>Hair Journal</span> app! 😉</p>
  </>
);
Page.slug = '/add-to-home-screen';
Page.title = 'How to add hey.hair to Home Screen?';
Page.description = 'Did you know, that you can add hey.hair web app to your home screen?';
Page.created = '2022-04-29';
Page.updated = '2022-04-29';
Page.cmsId = 2;
Page.tab = Tab.Ideas;
Page.level = 2;
export default Page;
