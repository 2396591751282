import { faBroadcastTower, faChevronRight, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import Link from 'next/link';
import Post2 from '../../../pages/add-to-home-screen';
import Post4 from '../../../pages/origin-story';
import Post3 from '../../../pages/tech';
import { useModel } from '../../model';
import { PageComponentStaticProps } from '../../page';
import { Header } from '../Header';

export const Ideas = () => {
  const model = useModel();

  return (
    <div className='flex flex-col flex-1 pt-5'>
      <Header>
        <FontAwesomeIcon className='shrink-0 h-3.5 fill-gradientSvg' icon={faLightbulb} />
      </Header>
      <div className='text-base text-fg-4 px-5 pb-5'>Learn more about the app. Get cool new ideas to improve your hair treatment routine.</div>
      <ul className='flex flex-col'>
        {([,
          Post3,
          Post4,
          Post2
        ] as PageComponentStaticProps[]).filter(item => item !== Post3 || model.data.blogPosts.some(({ cmsId }) => cmsId === item.cmsId)).map(item => (
          <li key={item.slug} className='group'>
            <Link legacyBehavior href={item.slug} scroll={false}>
              <a className={cn(
                'flex justify-between items-center gap-5 p-5 w-full min-h-[calc(var(--unit)_*_22)]',
                'border-t group-last:border-b border-border text-base',
                'transition duration-150 active:text-fg-max active:bg-bg-2'
              )}>
                <div className={cn(
                  '[--size:_calc(var(--unit)_*_1.25)]',
                  '[--gap:_calc((var(--unit)_*_5_-_var(--size))_/_2)]',
                  'flex',
                  'gap-[var(--gap)]',
                  '-ml-[calc(var(--gap)_+_var(--size))]'
                )}>
                  <div className={cn('mt-2.25 h-[var(--size)] aspect-square bg-unread rounded-full', {
                    'invisible': model.data.blogPosts.some(({ cmsId }) => cmsId === item.cmsId)
                  })} />
                  <div className='flex-1'>{item.title}</div>
                </div>
                <FontAwesomeIcon className='shrink-0 h-3.5 fill-gradientSvg' icon={faChevronRight} />
              </a>
            </Link>
          </li>
        ))}
      </ul>
      <div className='flex flex-1 justify-center items-center'>
        <div className='text-base text-fg-6 bg-bg-2 flex flex-col items-center my-5 p-5 gap-4 rounded-xl'>
          <FontAwesomeIcon className='h-4' icon={faBroadcastTower} />
          <div>Stay tuned for more posts!</div>
        </div>
      </div>
    </div>
  );
};
