import { faHomeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { Header } from '../Header';

interface Props {
  notFound?: boolean
}

export const Error = (props: Props) => (
  <div className='flex flex-col flex-1 gap-2.5 justify-center items-center'>
    <Header />
    <div className='flex justify-center items-center rounded-full h-36 aspect-square mb-5 bg-bg-3 text-[30px]'>😓</div>

    <div className='text-base text-fg-3'>Oops!</div>
    <div className='text-base text-fg-3'>{props.notFound ? 'We could not find this page' : 'Something went wrong'}…</div>

    <Link legacyBehavior href='/' scroll={false} replace>
      <a className='flex items-center px-6 h-12 gap-1.5 mt-5 rounded-full text-white bg-gradient text-base font-semibold whitespace-nowrap active:scale-95 transition duration-150'>
        <FontAwesomeIcon className='shrink-0 h-3.5' icon={faHomeAlt} />Go to Home Page
      </a>
    </Link>
  </div>
);
