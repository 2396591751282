import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import Link from 'next/link';
import { MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import { UrlObject } from 'url';
import { useForceRenderOnScroll } from '../hooks/useForceRenderOnScroll';
import { useRuntimeEnv } from '../hooks/useRuntimeEnv';

export const Header = (props: PropsWithChildren<{
  left?: ReactNode
  leftNotHeaderButton?: boolean
  right?: ReactNode
  fullWidth?: boolean
  overflowVisible?: boolean
}>) => {
  useForceRenderOnScroll();

  const runtimeEnv = useRuntimeEnv();

  return (
    <header className={cn(
      'z-headerFooter select-none overflow-hidden',
      'flex w-full h-[var(--header-height)] px-content',
      'fixed top-0 left-0'
    )}>
      <div className={cn('absolute top-0 left-0 w-full h-full', {
        'bg-[linear-gradient(0deg,_transparent_80%,_black_20%)]': runtimeEnv.touch
      })} />
      <div className={cn(
        'text-2sm',
        'flex items-center w-full h-full px-content',
        'bg-floating backdrop-blur-floating backdrop-saturate-floating',
        'absolute left-0 -top-[var(--border-width)]', {
          'rounded-t-lg': runtimeEnv.touch
        }
      )}>
        <div className='flex flex-1 h-full'>
          {props.left ? (
            <div className={cn('flex items-center', {
              '-ml-5': !props.leftNotHeaderButton
            })}>
              {props.left}
            </div>
          ) : <div className='invisible'>{props.right}</div>}
        </div>
        <div className={cn(
          'flex items-center h-full px-2.5', {
            'w-full': props.fullWidth,
            'overflow-hidden': !props.overflowVisible
          }
        )}>
          {props.children}
        </div>
        <div className='flex flex-1 justify-end h-full'>
          {props.right ? <div className='-mr-5 flex items-center'>{props.right}</div> : <div className='invisible'>{props.left}</div>}
        </div>
      </div>
      <div className='absolute left-0 bottom-0 w-full h-border bg-border' style={{
        opacity: runtimeEnv.browser ? Math.min(1, document.documentElement.scrollTop / 50) : 0
      }} />
    </header>
  );
};

export const HeaderButton = (props: PropsWithChildren<{
  icon?: IconDefinition
  tooltip?: string
  filled?: boolean
  selected?: boolean
  href?: string | UrlObject
  replace?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}>) => {
  const className = cn('group flex shrink-0 transition duration-150', props.children ? 'active:scale-95' : 'active:scale-90');

  const content = (
    <div className={cn(
      'flex items-center gap-1.5 m-2.5 h-6 rounded-full font-semibold leading-none transition duration-150',
      props.filled ? 'px-3' : 'px-2.5',
      (() => {
        if (props.filled) {
          return 'bg-gradient';
        }

        if (props.selected) {
          return 'bg-floating-2';
        }

        return 'group-active:bg-floating-2';
      })()
    )}>
      {props.icon && (
        <FontAwesomeIcon
          className={cn(
            'shrink-0 h-2.5',
            props.filled ? 'text-white' : 'fill-gradientSvg', {
              'h-3': !props.children
            }
          )}
          icon={props.icon}
        />
      )}
      {props.children && (
        <div className={cn('leading-[1.2]', props.filled ? 'text-white' : 'text-gradient')}>
          {props.children}
        </div>
      )}
    </div>
  );

  return props.href ? (
    <Link legacyBehavior href={props.href} replace={props.replace} scroll={false}>
      <a title={props.tooltip} className={className}>
        {content}
      </a>
    </Link>
  ) : (
    <button title={props.tooltip} className={className} onClick={props.onClick}>
      {content}
    </button>
  );
};
