import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { webUrl } from '../../config';
import { useForceRenderOnScroll } from '../../hooks/useForceRenderOnScroll';
import { useRuntimeEnv } from '../../hooks/useRuntimeEnv';
import { useModel } from '../../model';
import { usePage } from '../../page';
import { Header, HeaderButton } from '../Header';
import styles from './styles.module.css';

export const Post = () => {
  useForceRenderOnScroll();
  const page = usePage();
  const router = useRouter();
  const model = useModel();
  const runtimeEnv = useRuntimeEnv();
  const headingRef = useRef<HTMLHeadingElement>(null);
  const headerTitleVisibility = runtimeEnv.browser && headingRef.current ?
    Math.min(1,
      Math.max(
        0, document.documentElement.scrollTop - headingRef.current.getBoundingClientRect().top - headingRef.current.getBoundingClientRect().height / 4
      ) / headingRef.current.getBoundingClientRect().height
    ) : 0;

  useEffect(() => {
    if (!model.data.empty && !model.data.blogPosts.find(({ cmsId }) => page.cmsId === cmsId)?.seen) {
      model.seen(page.cmsId);
    }
  }, [ model.data ]);

  return (
    <>
      <Head>
        <title key='title'>{page.title}</title>
        <meta key='description'         name='description'                content={page.description} />
        <meta key='og:title'            property='og:title'               content={page.title} />
        <meta key='og:description'      property='og:description'         content={page.description} />
        <meta key='twitter:title'       name='twitter:title'              content={page.title} />
        <meta key='twitter:description' name='twitter:description'        content={page.description} />
        <meta key='og:type'             property='og:type'                content='article' />
        <meta                           property='article:published_time' content={page.created} />
        <meta                           property='article:modified_time'  content={page.updated} />
        <link rel='canonical'           href={`${webUrl}${page.slug}`} />
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@type': 'BlogPosting',
              '@id': `${webUrl}${router.pathname}/#BlogPosting`,
              'url': `${webUrl}${router.pathname}`,
              'name': page.title,
              'headline': page.title,
              'description': page.description,
              'datePublished': page.created,
              'dateModified': page.updated,
              'inLanguage': 'en-US'
            })
          }}
        />
      </Head>
      <div className='p-5'>
        <Header left={<HeaderButton icon={faArrowLeft} tooltip='Back' href='/ideas' />}>
          <div className='font-semibold truncate' style={{
            transform: `translateY(${(1 - headerTitleVisibility) * 10}px)`,
            opacity: headerTitleVisibility
          }}>
            {page.title}
          </div>
        </Header>
        <h1 ref={headingRef} className='text-4lg font-bold'>{page.title}</h1>
        <time className='flex mt-2.5 mb-10 text-sm font-normal text-fg-5' dateTime={page.updated}>{format(parseISO(page.updated), 'MMMM do yyyy')}</time>
        <div className={cn(styles.textPageContent, 'flex flex-col gap-5 mb-15', {
          [styles.ios]: runtimeEnv.ios
        })}>
          <page.Component />
        </div>
      </div>
    </>
  );
};
