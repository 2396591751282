import { useLayoutEffect, useState } from 'react';

export const browser = typeof window !== 'undefined';
export const touch = browser && 'ontouchstart' in window;
export const iphone = browser && window.navigator.userAgent.includes('iPhone');
export const iphone154 = browser && window.navigator.userAgent.includes('iPhone') && window.navigator.userAgent.substring(
  window.navigator.userAgent.indexOf(' OS') + 4,
  window.navigator.userAgent.indexOf(' ', window.navigator.userAgent.indexOf(' OS') + 4)
) >= '15_4';
export const ios = browser && /(iPhone|iPad|iPod)/.test(window.navigator.userAgent);

export const useRuntimeEnv = () => {
  const [get, set] = useState({
    browser: false,
    touch: false,
    iphone: false,
    iphone154: false,
    ios: false
  });

  if (browser) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => set({
      browser,
      touch,
      iphone,
      iphone154,
      ios
    }), []);
  }

  return get;
};
