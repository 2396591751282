import { faArrowLeft, faBath, faChevronDown, faChevronLeft, faChevronRight, faComment, faCommentSlash, faCopy, faCut, faEllipsisH, faHeart, faPaste, faPlus, faStar, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { addDays, addMonths, format, formatDistanceToNowStrict, getDay, getDaysInMonth, isFuture, isSameDay, isSameMonth, isToday, isTomorrow, isWeekend, isYesterday, parseISO, startOfMonth, startOfWeek } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import uniq from 'lodash/uniq';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { createRef, CSSProperties, forwardRef, RefObject, SetStateAction, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useForceRender } from '../../hooks/useForceRender';
import { useForceRenderPeriodically } from '../../hooks/useForceRenderPeriodically';
import { useMenuKey } from '../../hooks/useMenuKey';
import { useOuterDismissKey } from '../../hooks/useOuterDismissKey';
import { browser, iphone, touch, useRuntimeEnv } from '../../hooks/useRuntimeEnv';
import { Product, useModel } from '../../model';
import { usePage } from '../../page';
import { CategoryDot } from '../CategoryDot';
import { Header, HeaderButton } from '../Header';

interface GlobalDragState {
  index: number
  dropIndex: number
  dropEnding: boolean
  startScrollTop: number
  rect: DOMRect
  staticRects: DOMRect[]
}

// if starts at or reaches 0 during drag, then touch movements trigger jumps to touch start location
const getMinScrollDuringDrag = () => iphone && !(window.navigator as any).standalone ? 1 : 0;

export const Day = () => {
  useForceRenderPeriodically();
  const page = usePage();
  const router = useRouter();
  const model = useModel();
  const runtimeEnv = useRuntimeEnv();
  const ref = useRef<HTMLDivElement>(null);
  const commentTextArea = useRef<HTMLTextAreaElement>(null);
  const salonCommentTextArea = useRef<HTMLTextAreaElement>(null);
  const [dateMenu, setDateMenu] = useOuterDismissKey<Date>();
  const [menu, setMenu] = useOuterDismissKey(false);
  const addButton = useRef<HTMLButtonElement>(null);
  const added = useRef(false);
  const date = router.query.date as string;
  const dateObject = parseISO(date);
  const data = model.getDay(date);
  const products = data?.products || [];
  const comment = data?.comment || '';
  const salonComment = data?.salonComment || '';
  const rating = data?.rating;
  const [showRating, setShowRating] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [showSalonComment, setShowSalonComment] = useState(false);
  const dateFriendlyName = (() => {
    if (isToday(dateObject)) {
      return 'Today';
    }

    if (isYesterday(dateObject)) {
      return 'Yesterday';
    }

    if (isTomorrow(dateObject)) {
      return 'Tomorrow';
    }
  })();

  useEffect(() => {
    if (!touch && showComment && commentTextArea.current) {
      commentTextArea.current!.focus();
    }
  }, [showComment]);

  useEffect(() => {
    if (!touch && showSalonComment && salonCommentTextArea.current) {
      salonCommentTextArea.current!.focus();
    }
  }, [showSalonComment]);

  // DRAG
  const [dragScrollCounter, dragScroll] = useReducer(x => x + 1, 0);
  const [globalDragState, setGlobalDragState] = useState<GlobalDragState>();
  const productViews = useRef<RefObject<ProductViewApi>[]>([]);

  if (productViews.current.length !== products.length) {
    productViews.current = range(0, products.length).map((_, i) => productViews.current[i] || createRef());
  }

  useEffect(() => {
    setShowRating(!!rating);
    setShowComment(!!comment);
    setShowSalonComment(!!salonComment);
  }, [ date ]);

  if (browser) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      if (added.current) {
        if (document.documentElement.scrollHeight > document.documentElement.clientHeight + 1 + (iphone ? 0 : 100)) {
          window.scrollTo({ top: document.documentElement.scrollHeight });
        }

        added.current = false;
      }
    }, [added.current]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      if (globalDragState && addButton.current) {
        const maxScroll = 6;
        const minGap = 100 * window.innerHeight / 550;
        const topGap = globalDragState.rect.top - document.querySelector('header')!.getBoundingClientRect().bottom;
        const bottomGap = document.querySelector('footer')!.getBoundingClientRect().top - globalDragState.rect.bottom;

        if (topGap < minGap && document.documentElement.scrollTop > getMinScrollDuringDrag()) {
          window.scrollBy({ top: Math.round(-1 * maxScroll * (minGap - topGap) / minGap) });
        } else if (bottomGap < minGap && globalDragState.rect.bottom < addButton.current.getBoundingClientRect().bottom) {
          window.scrollBy({ top: Math.round(maxScroll * (minGap - bottomGap) / minGap) });
        }
      }
    }, [globalDragState, dragScrollCounter]);
  }

  useEffect(() => {
    if (globalDragState) {
      window.addEventListener('scroll', dragScroll);

      return () => window.removeEventListener('scroll', dragScroll);
    }
  }, [globalDragState === undefined]);

  useEffect(() => {
    if (globalDragState) {
      for (let index = 0; index < globalDragState.staticRects.length; index++) {
        const scrollTopDelta = document.documentElement.scrollTop - globalDragState.startScrollTop;
        const before = globalDragState.staticRects[index - 1];
        const after = globalDragState.staticRects[index + 1];

        if ((!before || globalDragState.rect.top > before.bottom - scrollTopDelta - before.height / 2) && (!after || globalDragState.rect.bottom < after.top - scrollTopDelta + after.height / 2)) {
          if (globalDragState.dropIndex !== index) {
            setGlobalDragState(current => ({
              ...current!,
              dropIndex: index
            }));
          }

          break;
        }
      }
    }
  }, [globalDragState, dragScrollCounter]);

  return (
    <div ref={ref} className='flex flex-col gap-10 p-5'>
      <Header
        left={<HeaderButton icon={faArrowLeft} tooltip='Back' href={page.backHref([ 'date' ], { prefixed: true })} />}
        right={<HeaderButton icon={faEllipsisH} tooltip='Actions' selected={menu} onClick={() => setMenu(true)} />}
      >
        <HeaderButton icon={faChevronLeft} tooltip='Previous Day' href={page.nextHref('', 'date', model.serializeDate(addDays(dateObject, -1)))} replace />
        <button
          className='flex justify-center items-center gap-1.5 h-5 w-[calc(var(--unit)_*_37)] relative transition duration-150 active:scale-95'
          onClick={() => setDateMenu(dateObject)}
        >
          <div className='text-5sm font-extrabold tracking-widest uppercase text-fg-6 absolute -top-2.25'>
            {
              dateFriendlyName
              ||
              formatDistanceToNowStrict(addDays(dateObject, isFuture(dateObject) ? 1 : 0), { unit: 'day', roundingMethod: 'floor', addSuffix: true })
            }
          </div>
          <FontAwesomeIcon className='shrink-0 h-2.75 fill-gradientSvg' icon={salonComment ? faCut : faBath} />
          <div className='font-semibold'>
            {format(dateObject, 'MMMM d')}
          </div>
          <div className='flex items-center text-3sm font-semibold tracking-widest uppercase text-fg-5 mt-0.5'>{format(dateObject, 'EEE')}</div>
          <FontAwesomeIcon className='shrink-0 h-2' icon={faChevronDown} />
        </button>
        <HeaderButton icon={faChevronRight} tooltip='Next Day' href={page.nextHref('', 'date', model.serializeDate(addDays(dateObject, 1)))} replace />
      </Header>
      <AnimatePresence>
        {useMemo(() => dateMenu && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            initial={{ opacity: 0, scale: 0.97, translateX: '-50%' }}
            animate={{ opacity: 1, scale: 1, translateX: '-50%' }}
            exit={{ opacity: 0, scale: 0.97, translateX: '-50%' }}
            className={cn(
              'z-menu flex flex-col gap-5 p-5 rounded-xl fixed whitespace-nowrap select-none',
              'top-[calc(var(--header-height)_-_var(--unit))]',
              'left-1/2',
              'bg-floating backdrop-blur-floating backdrop-saturate-floating shadow-floating'
            )}
          >
            <div className='flex justify-between'>
              <button title='Previous Month' className='flex items-center px-5 py-2.5 -m-2.5 rounded-xl transition duration-150 active:scale-90 active:bg-floating-2' onClick={event => {
                event.stopPropagation();
                setDateMenu(addMonths(dateMenu, -1));
              }}>
                <FontAwesomeIcon className='shrink-0 h-3.5 fill-gradientSvg' icon={faChevronLeft} />
              </button>
              <div className='flex gap-1.5 text-sm'>
                <div className='font-semibold'>{format(dateMenu, 'MMMM')}</div>
                <div className='font-normal text-fg-5'>{format(dateMenu, 'yyyy')}</div>
              </div>
              <button title='Next Month' className='flex items-center px-5 py-2.5 -m-2.5 rounded-xl transition duration-150 active:scale-90 active:bg-floating-2' onClick={event => {
                event.stopPropagation();
                setDateMenu(addMonths(dateMenu, 1));
              }}>
                <FontAwesomeIcon className='shrink-0 h-3.5 fill-gradientSvg' icon={faChevronRight} />
              </button>
            </div>
            <div className='shrink-0 -mx-5 h-border bg-border' />
            <div className='flex'>
              {range(0, 7).map(i => (
                <div key={i} className='flex flex-1 justify-center text-3sm font-semibold tracking-widest uppercase text-fg-5'>
                  {format(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), i), 'EEE')}
                </div>
              ))}
            </div>
            <div className='grid gap-0.5 grid-cols-[repeat(7,calc(var(--unit)_*_10))] auto-rows-[calc(var(--unit)_*_10)]'>
              {[
                ...reverse(range(1, getDay(startOfMonth(dateMenu)) || 7)).map(day => addDays(startOfMonth(dateMenu), -day)),
                ...range(0, getDaysInMonth(startOfMonth(dateMenu))).map(day => addDays(startOfMonth(dateMenu), day))
              ].map(date => (
                <Link legacyBehavior key={date.toISOString()} href={page.nextHref('', 'date', model.serializeDate(date))} replace>
                  <a className={cn(
                    'flex justify-center items-center rounded-xl text-base font-semibold relative',
                    'transition duration-150',
                    (() => {
                      if (isToday(date)) {
                        return 'font-extrabold';
                      }

                      if (isSameDay(date, dateObject)) {
                        return 'text-fg-max';
                      }

                      if (isWeekend(date)) {
                        return 'text-fg-4';
                      }

                      return '';
                    })(), {
                      'invisible': !isSameMonth(date, dateMenu),
                      'bg-bg-3': isSameDay(date, dateObject),
                      'active:scale-90 active:bg-floating-2': !isSameDay(date, dateObject),
                      'active:text-fg-max': !isToday(date)
                    }
                  )}>
                    <div className={cn({ 'text-gradient': isToday(date) })}>{format(date, 'd')}</div>
                    {model.getDay(model.serializeDate(date)) && <div className='h-0.75 aspect-square rounded-full absolute bottom-1.5 bg-accent-3' />}
                  </a>
                </Link>
              ))}
            </div>
            <div className='shrink-0 -mx-5 h-border bg-border' />
            <Link legacyBehavior href={page.nextHref('', 'date', model.serializeDate(new Date()))} replace>
              <a className={cn(
                'flex self-center px-5 py-2.5 -my-2.5 rounded-full text-gradient text-sm font-semibold text-center',
                'transition duration-150',
                'active:scale-90 active:bg-floating-2'
              )} onClick={() => setDateMenu(new Date())}>
                Today
              </a>
            </Link>
          </motion.div>
        ), [ dateMenu, date, model.data.days ])}
      </AnimatePresence>
      <AnimatePresence>
        {menu && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className={cn(
              'z-menu flex flex-col gap-1 py-2.5 rounded-xl fixed whitespace-nowrap select-none',
              'top-[calc(var(--header-height)_-_var(--unit))]',
              'right-[calc(var(--unit)_*_2_+_var(--content-offset))]',
              'bg-floating backdrop-blur-floating backdrop-saturate-floating shadow-floating'
            )}
          >
            {products.length > 1 && (
              <button className={cn(
                'flex items-center h-12 gap-2.5 pl-3 pr-5 mx-2.5 rounded-md text-base',
                'transition duration-150 active:text-fg-max active:bg-floating-2'
              )} onClick={() => {
                model.copyBundleFromDate(dateObject, products);
              }}>
                <div className='flex justify-center items-center h-4 aspect-square'>
                  <FontAwesomeIcon className='shrink-0 h-4 fill-gradientSvg' icon={faCopy} />
                </div>
                Copy Bundle
              </button>
            )}
            {model.copiedBundle && (
              <button className={cn(
                'flex items-center h-12 gap-2.5 pl-3 pr-5 mx-2.5 rounded-md text-base',
                'transition duration-150 active:text-fg-max active:bg-floating-2'
              )} onClick={() => {
                model.pasteBundle(date);
              }}>
                <div className='flex justify-center items-center h-4 aspect-square'>
                  <FontAwesomeIcon className='shrink-0 h-4 fill-gradientSvg' icon={faPaste} />
                </div>
                Paste Bundle
              </button>
            )}
            {products.length > 1 && !showRating && (
              <button className={cn(
                'flex items-center h-12 gap-2.5 pl-3 pr-5 mx-2.5 rounded-md text-base',
                'transition duration-150 active:text-fg-max active:bg-floating-2'
              )} onClick={() => {
                window.scrollTo(0, 0);
                setShowRating(true);
              }}>
                <div className='flex justify-center items-center h-4 aspect-square'>
                  <FontAwesomeIcon className='shrink-0 h-4 fill-gradientSvg' icon={faStar} />
                </div>
                Rate Bundle
              </button>
            )}
            {(comment || showComment) ? (
              <button className={cn(
                'flex items-center h-12 gap-2.5 pl-3 pr-5 mx-2.5 rounded-md text-base',
                'transition duration-150 active:text-fg-max active:bg-floating-2'
              )} onClick={() => {
                model.deleteDayComment(date, 'Comment removed');
                setShowComment(false);
              }}>
                <div className='flex justify-center items-center h-4 aspect-square'>
                  <FontAwesomeIcon className='shrink-0 h-3.75 fill-gradientSvg' icon={faCommentSlash} />
                </div>
                Remove Comment
              </button>
            ) : (
              <button className={cn(
                'flex items-center h-12 gap-2.5 pl-3 pr-5 mx-2.5 rounded-md text-base',
                'transition duration-150 active:text-fg-max active:bg-floating-2'
              )} onClick={() => {
                window.scrollTo(0, 0);
                setShowComment(true);
              }}>
                <div className='flex justify-center items-center h-4 aspect-square'>
                  <FontAwesomeIcon className='shrink-0 h-3.75 fill-gradientSvg' icon={faComment} />
                </div>
                Add Comment
              </button>
            )}
            {(salonComment || showSalonComment) ? (
              <button className={cn(
                'flex items-center h-12 gap-2.5 pl-3 pr-5 mx-2.5 rounded-md text-base',
                'transition duration-150 active:text-fg-max active:bg-floating-2'
              )} onClick={() => {
                model.deleteDaySalonComment(date, 'Hairdresser notes removed');
                setShowSalonComment(false);
              }}>
                <div className='flex justify-center items-center h-4 aspect-square'>
                  <FontAwesomeIcon className='shrink-0 h-3.5 fill-gradientSvg' icon={faCut} />
                </div>
                Remove Hairdresser Notes
              </button>
            ) : (
              <button className={cn(
                'flex items-center h-12 gap-2.5 pl-3 pr-5 mx-2.5 rounded-md text-base',
                'transition duration-150 active:text-fg-max active:bg-floating-2'
              )} onClick={() => {
                window.scrollTo(0, 0);
                setShowSalonComment(true);
              }}>
                <div className='flex justify-center items-center h-4 aspect-square'>
                  <FontAwesomeIcon className='shrink-0 h-3.5 fill-gradientSvg' icon={faCut} />
                </div>
                Add Hairdresser Notes
              </button>
            )}
            {data && (
              <>
                <div className='shrink-0 mx-2.5 my-1.5 h-border bg-border'></div>
                <button className={cn(
                  'flex items-center h-12 gap-2.5 pl-3 pr-5 mx-2.5 rounded-md text-base',
                  'transition duration-150 active:text-fg-max active:bg-floating-2'
                )} onClick={() => {
                  model.deleteDay(date, 'This day is now empty');
                  setShowComment(false);
                  setShowSalonComment(false);
                  setShowRating(false);
                }}>
                  <div className='flex justify-center items-center h-4 aspect-square'>
                    <FontAwesomeIcon className='shrink-0 h-3.75 fill-gradientSvg' icon={faTrash} />
                  </div>
                  Clear This Day
                </button>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {(
        <>
          {(showRating || rating) && products.length > 1 && (
            <div className='flex flex-col gap-2'>
              <div className='flex justify-center text-3sm font-semibold tracking-widest uppercase text-fg-5 select-none'>
                {data?.rating ? <>You&apos;ve given this bundle {data.rating} star{data.rating > 1 ? 's' : ''}</> : <>Rate this bundle</>}
              </div>
              <div className='flex justify-center'>
                {range(1, 6).map(value => (
                  <button
                    key={value}
                    title={`Rate ${value} Star${value > 1 ? 's' : ''}`}
                    className={cn(
                      'p-2.5 rounded-full transition active:scale-80 active:bg-bg-2',
                      data && value <= (data.rating || 0) ? 'text-accent-3' : 'text-fg-6'
                    )}
                    onClick={() => model.toggleDayRating(date, value)}
                  >
                    <FontAwesomeIcon className='shrink-0 h-8' icon={faStar} />
                  </button>
                ))}
              </div>
            </div>
          )}
          {(showComment || comment) && (
            <div className='flex flex-col gap-2.5'>
              <div className='flex justify-center text-3sm font-semibold tracking-widest uppercase text-fg-5 select-none'>
                Comment
              </div>
              <div className='flex flex-1 relative'>
                <div className='text-base leading-normal px-5 w-full whitespace-pre-wrap pointer-events-none invisible'>
                  {comment}{comment === '' || comment.endsWith('\n') ? '\n' : ''}
                </div>
                <textarea
                  ref={commentTextArea}
                  className='text-base leading-normal px-5 w-full h-full resize-none bg-bg absolute'
                  placeholder={runtimeEnv.browser && document.activeElement === commentTextArea.current ? 'What\'s on your mind?' : `${runtimeEnv.touch ? 'Tap' : 'Click'} here to type a comment…`}
                  spellCheck
                  value={comment}
                  onChange={event => model.updateDayComment(date, event.target.value)}
                  onFocus={model.focusEditor}
                  onBlur={model.blurEditor}
                />
              </div>
            </div>
          )}
          {(showSalonComment || salonComment) && (
            <div className='flex flex-col gap-2.5'>
              <div className='flex justify-center text-3sm font-semibold tracking-widest uppercase text-fg-5 select-none'>
                Hairdresser Appointment
              </div>
              <div className='flex flex-1 relative'>
                <div className='text-base leading-normal px-5 w-full whitespace-pre-wrap pointer-events-none invisible'>
                  {salonComment}{salonComment === '' || salonComment.endsWith('\n') ? '\n' : ''}
                </div>
                <textarea
                  ref={salonCommentTextArea}
                  className='text-base leading-normal px-5 w-full h-full resize-none bg-bg absolute'
                  placeholder={runtimeEnv.browser && document.activeElement === salonCommentTextArea.current ? 'What\'s on your mind?' : `${runtimeEnv.touch ? 'Tap' : 'Click'} here to type some notes…`}
                  spellCheck
                  value={salonComment}
                  onChange={event => model.updateDaySalonComment(date, event.target.value)}
                  onFocus={model.focusEditor}
                  onBlur={model.blurEditor}
                />
              </div>
            </div>
          )}
          <div className='flex flex-col gap-2.5'>
            {products.length > 0 && (
              <div className='flex justify-center text-3sm font-semibold tracking-widest uppercase text-fg-5 select-none'>
                {products.length > 1 ? <>Bundle of {products.length} products</> : <>Product</>}
              </div>
            )}
            <div className='flex flex-col gap-5'>
              {sortBy(products, product => product.position).map((product, index) => (
                <ProductView
                  key={product.uuid}
                  ref={productViews.current[index]}
                  date={date}
                  index={index}
                  product={product}
                  products={products}
                  productViews={productViews.current}
                  rootRef={ref}
                  globalDragState={globalDragState}
                  setGlobalDragState={setGlobalDragState}
                />
              ))}
              {products.length === 0 && model.copiedBundle && (
                <>
                  <div className='flex flex-col gap-2.5'>
                    <div className='flex justify-center text-3sm font-semibold tracking-widest uppercase text-fg-5 select-none'>
                      Paste bundle of {model.copiedBundle.products.length} products
                    </div>
                    <div className='flex justify-center gap-2'>
                      {uniq(model.copiedBundle.products.map(({ categoryId }) => categoryId)).map(categoryId => <CategoryDot key={categoryId} id={categoryId} />)}
                    </div>
                    <button
                      ref={addButton}
                      title='Paste Bundle'
                      className='flex justify-center items-center p-5 rounded-xl text-base bg-bg-3 transition duration-150 active:scale-95'
                      onClick={() => model.pasteBundle(date)}
                    >
                      <FontAwesomeIcon className='shrink-0 h-5 text-fg-6' icon={faPaste} />
                    </button>
                  </div>
                  <div className='flex justify-center text-3sm font-semibold tracking-widest uppercase text-fg-5 select-none'>
                    or
                  </div>
                </>
              )}
              <div className='flex flex-col gap-2.5'>
                {products.length === 0 && !model.copiedBundle && (
                  <div className='flex justify-center text-3sm font-semibold tracking-widest uppercase text-fg-5 select-none'>
                    Add one or more products
                  </div>
                )}
                <button
                  ref={addButton}
                  title='Add Product'
                  className='flex justify-center items-center p-5 rounded-xl text-base bg-bg-3 transition duration-150 active:scale-95'
                  onClick={() => {
                    added.current = true;
                    model.addDayProduct(date);
                  }}
                >
                  <FontAwesomeIcon className='shrink-0 h-5 text-fg-6' icon={faPlus} />
                </button>
                {products.length === 0 && model.copiedBundle && (
                  <div className='flex justify-center text-3sm font-semibold tracking-widest uppercase text-fg-5 select-none'>
                    Start adding products manually
                  </div>
                )}
                {products.length === 1 && (
                  <div className='flex justify-center text-3sm font-semibold tracking-widest uppercase text-fg-5 select-none'>
                    Add one more to make a bundle
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

interface ProductViewApi {
  getElement: () => HTMLDivElement
}

const ProductView = forwardRef<ProductViewApi, {
  date: string
  index: number
  product: Product
  products: Product[]
  productViews: RefObject<ProductViewApi>[]
  rootRef: RefObject<HTMLDivElement>
  globalDragState?: GlobalDragState
  setGlobalDragState: (action: SetStateAction<GlobalDragState | undefined>) => void
}>((props, forwardedRef) => {
  const { index, date, globalDragState } = props;
  const model = useModel();
  const runtimeEnv = useRuntimeEnv();
  const render = useForceRender();
  const ref = useRef<HTMLDivElement>(null);
  const categoryMenuButtonRef = useRef<HTMLButtonElement>(null);
  const textArea = useRef<HTMLTextAreaElement>(null);
  const dragStartTimeoutId = useRef(0);
  const [categoryMenu, setCategoryMenu] = useMenuKey(false);

  // DRAG
  useImperativeHandle(forwardedRef, () => ({
    getElement: () => ref.current!
  }), []);

  const dragGrip = useRef<HTMLDivElement>(null);
  const [localDragState, setLocalDragState] = useState<{ startY: number, currentY: number, dropDistance: number }>();
  const dragging = !!localDragState;
  const dropping = !!localDragState?.dropDistance;
  const dropTarget = !!globalDragState && !dragging;

  useEffect(() => {
    if (globalDragState) {
      setLikeFloating(undefined);
      setCategoryMenu(false);
      setCanShowAutocomplete(false);
    }
  }, [ globalDragState === undefined ]);

  const onDragGripPress = (event: Event) => {
    if (!localDragState && !globalDragState && event.target === dragGrip.current && !dragStartTimeoutId.current && (!touch || !model.editorFocused)) {
      event.preventDefault();
      event.stopPropagation();

      const startY = ((event as TouchEvent).touches ? (event as TouchEvent).touches[0] : event as MouseEvent).clientY;
      const startDrag = () => {
        if (document.documentElement.scrollTop < getMinScrollDuringDrag()) {
          document.documentElement.scrollTop = getMinScrollDuringDrag();
        }

        setLocalDragState({ startY, currentY: startY, dropDistance: 0 });
        props.setGlobalDragState({
          index,
          dropIndex: index,
          dropEnding: false,
          startScrollTop: document.documentElement.scrollTop,
          rect: ref.current!.getBoundingClientRect(),
          staticRects: props.productViews.map(({ current }) => current!.getElement().getBoundingClientRect())
        });
        model.startDrag();
      };

      if (touch) {
        dragStartTimeoutId.current = window.setTimeout(startDrag, 150);
      } else {
        startDrag();
      }
    }
  };

  const onDragGripTouchMoveOrEnd = () => {
    window.clearTimeout(dragStartTimeoutId.current);
    dragStartTimeoutId.current = 0;
  };

  useEffect(() => {
    if (localDragState && globalDragState) {
      const onMove = (event: Event) => {
        if (!localDragState.dropDistance) {
          event.preventDefault();
          event.stopPropagation();

          const currentY = ((event as TouchEvent).touches ? (event as TouchEvent).touches[0] : event as MouseEvent).clientY;
          setLocalDragState(current => ({ ...current!, currentY }));
          props.setGlobalDragState(current => ({ ...current!, rect: ref.current!.getBoundingClientRect() }));
        }
      };

      const onDrop = (event: Event) => {
        if (!localDragState.dropDistance) {
          event.preventDefault();
          event.stopPropagation();

          const dropDistance = (globalDragState.staticRects[globalDragState.dropIndex].top - (document.documentElement.scrollTop - globalDragState.startScrollTop)) - ref.current!.getBoundingClientRect().top;

          if (dropDistance) {
            setLocalDragState(current => ({ ...current!, dropDistance }));
          } else {
            onDropEnd();
          }

          model.endDrag();
        }
      };

      const onTransitionEnd = (event: TransitionEvent) => {
        if (event.target === ref.current && localDragState.dropDistance) {
          onDropEnd();
        }
      };

      const onDropEnd = () => {
        setLocalDragState(undefined);
        props.setGlobalDragState(current => ({ ...current!, dropEnding: true }));

        if (globalDragState.dropIndex !== index) {
          model.updateDayProductPosition(date, props.products[index]!.position, props.products[globalDragState.dropIndex]!.position);
        }

        props.setGlobalDragState(undefined);
      }

      document.addEventListener('touchmove', onMove, { passive: false });
      window.addEventListener('mousemove', onMove);
      window.addEventListener('touchend', onDrop);
      window.addEventListener('mouseup', onDrop);
      ref.current!.addEventListener('transitionend', onTransitionEnd);

      return () => {
        document.removeEventListener('touchmove', onMove);
        window.removeEventListener('mousemove', onMove);
        window.removeEventListener('touchend', onDrop);
        window.removeEventListener('mouseup', onDrop);
        ref.current!.removeEventListener('transitionend', onTransitionEnd);
      };
    }
  }, [ localDragState, globalDragState ]);

  // AUTOCOMPLETE
  const textAreaSelection = useRef<HTMLDivElement>(null);
  const [[selectionStart, selectionEnd], setSelection] = useState([0, 0]);
  const [autocompleteIndexBeingPressed, setAutocompleteIndexBeingPressed] = useState<number>();
  const [canShowAutocomplete, setCanShowAutocomplete] = useState(false);
  const [autocompleteIndex, setAutocompleteIndex] = useState(0);

  const getAutocompleteProducts = () => {
    const all = orderBy(
      toPairs(
        groupBy(
          model.data.days
            .flatMap(({ products }) => products)
            .filter(({ name }) => name),
          model.productKey
        )
      )
        .map(([key, products]) => ({
          key,
          ...products[0],
          likeCount: products.filter(({ liked }) => liked).length
        })),
      [ 'likeCount', 'categoryId', 'name' ],
      [ 'desc', 'asc', 'asc' ]
    ).filter(product => model.productKey(props.product) !== model.productKey(product));
    const startWithMatches = all.filter(product => props.product.name && product.name.toLowerCase().startsWith(props.product.name));
    const containsMatches = all.filter(product => props.product.name && props.product.name.toLowerCase().split(' ').every(part => product.name.toLowerCase().includes(part)));

    return [
      ...startWithMatches,
      ...containsMatches.filter(product => !startWithMatches.some(({ uuid }) => product.uuid === uuid)),
    ].slice(0, 3);
  };

  const showAutocomplete = (canShowAutocomplete || autocompleteIndexBeingPressed !== undefined) && getAutocompleteProducts().length > 0;

  useEffect(() => {
    if (showAutocomplete) {
      render();

      window.addEventListener('scroll', render);

      return () => window.removeEventListener('scroll', render);
    }
  }, [ showAutocomplete ]);

  useEffect(() => {
    setAutocompleteIndexBeingPressed(undefined);
  }, [ autocompleteIndex ]);

  useEffect(() => {
    if (autocompleteIndexBeingPressed !== undefined) {
      const reset = () => window.setTimeout(() => setAutocompleteIndexBeingPressed(undefined));

      window.addEventListener('mouseup', reset);
      window.addEventListener('touchend', reset);

      return () => {
        window.removeEventListener('mouseup', reset);
        window.removeEventListener('touchend', reset);
      };
    }
  }, [ autocompleteIndexBeingPressed ]);

  useEffect(() => {
    const onChange = () => {
      if (textArea.current === document.activeElement) {
        setSelection([textArea.current!.selectionStart, textArea.current!.selectionEnd]);
      }
    };

    document.addEventListener('selectionchange', onChange);

    return () => document.removeEventListener('selectionchange', onChange);
  }, []);

  // LIKE
  const likeButton = useRef<HTMLDivElement>(null);
  const [likeFloating, setLikeFloating] = useState<{
    initialTransition: CSSProperties
    halfTransition: CSSProperties
    fullTransition: CSSProperties
  }>();

  useEffect(() => {
    let timeoutId1 = 0;
    let timeoutId2 = 0;

    if (likeFloating) {
      const coinFlip1 = Math.random() < 0.5;
      const coinFlip2 = Math.random() < 0.5;
      const gapToRightEdge = props.rootRef.current!.getBoundingClientRect().right - likeButton.current!.getBoundingClientRect().right;
      const minXMovement = Math.floor(gapToRightEdge / 2);

      setLikeFloating(current => ({
        ...current!,
        initialTransition: {
          opacity: 1,
          transform: 'none'
        }
      }));

      const doHalfAnimation = (positive1: boolean, positive2: boolean) => {
        setLikeFloating(current => ({
          ...current!,
          halfTransition: {
            '--translate-x': (positive1 ? 1 : -1) * (minXMovement + Math.floor(Math.random() * (gapToRightEdge - minXMovement))),
            '--rotate': (positive2 ? 1 : -1) * (3 + Math.round(Math.random() * 17)),
          } as CSSProperties
        }));
      };

      doHalfAnimation(coinFlip1, coinFlip2);
      setLikeFloating(current => ({
        ...current!,
        fullTransition: {
          transform: `translateY(${-window.innerHeight}px)`,
          opacity: 0
        }
      }));

      timeoutId1 = window.setTimeout(() => {
        doHalfAnimation(!coinFlip1, !coinFlip2);
      }, 2_000 + Math.floor(Math.random() * 3_000));

      timeoutId2 = window.setTimeout(() => {
        setLikeFloating(undefined);
      }, 10_000);
    }

    return () => {
      window.clearTimeout(timeoutId1);
      window.clearTimeout(timeoutId2);
    };
  }, [ likeFloating === undefined ]);

  return (
    <div
      ref={ref}

      style={localDragState && globalDragState ? {
        '--drag-top': localDragState.currentY - localDragState.startY + document.documentElement.scrollTop - globalDragState.startScrollTop,
        '--drag-translate': localDragState.dropDistance
      } as CSSProperties : {}}

      className={cn('flex flex-col', {
        'transition-transform will-change-transform duration-300': dragging || dropTarget,
        [cn(
          'z-floating relative',
          'top-[calc(var(--drag-top)_*_1px)]',
          'translate-y-[calc(var(--drag-translate)_*_1px)]'
        )]: dragging,
        'scale-[1.02]': dragging && !dropping,
        'translate-y-[calc(-100%_-_var(--unit)_*_5)]': dropTarget && index > globalDragState.index && globalDragState.dropIndex > globalDragState.index && index <= globalDragState.dropIndex,
        'translate-y-[calc(100%_+_var(--unit)_*_5)]': dropTarget && index < globalDragState.index && globalDragState.dropIndex < globalDragState.index && index >= globalDragState.dropIndex
      })}
    >
      <div className='flex relative ml-4'>
        <button
          ref={categoryMenuButtonRef}
          className='flex items-center gap-1.5 h-5 text-2sm font-semibold leading-none transition duration-150 active:scale-95'
          onClick={() => setCategoryMenu(true)}
        >
          <CategoryDot id={props.product.categoryId} />
          <div>{model.data.categories.find(({ id }) => id === props.product.categoryId)!.name}</div>
          <FontAwesomeIcon className='shrink-0 h-2' icon={faChevronDown} />
        </button>

        <AnimatePresence>
          {categoryMenu && (
            <motion.div
              transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className={cn(
                'z-menu flex flex-col gap-0.5 p-2.5 rounded-xl whitespace-nowrap select-none',
                'absolute -left-5',
                'bg-floating backdrop-blur-floating backdrop-saturate-floating shadow-floating',
                (() => {
                  const topPoint = document.querySelector('header')!.getBoundingClientRect().bottom;
                  const bottomPoint = document.querySelector('footer')!.getBoundingClientRect().top;
                  const mainSpace = bottomPoint - topPoint;

                  if (textAreaSelection.current && textAreaSelection.current.getBoundingClientRect().top < topPoint + mainSpace / 3) {
                    return 'top-5';
                  }

                  if (textAreaSelection.current && textAreaSelection.current.getBoundingClientRect().bottom > bottomPoint - mainSpace / 3) {
                    return 'bottom-5';
                  }

                  return `-top-[calc((0.625rem_*_2_+_8_*_(0.625rem_*_2_+_1rem)_+_7_*_0.125rem)_/_2)]`;
                })()
              )}
            >
              {model.data.categories.map(category => (
                <button
                  key={category.id}
                  className={cn(
                    'flex items-center gap-2.5 p-2.5 rounded-md text-sm',
                    'transition duration-150 active:text-fg-max active:bg-bg-3', {
                      'text-fg-max bg-bg-3': props.product.categoryId === category.id
                    }
                  )}
                  onClick={() => model.updateDayProductCategory(date, props.product.position, category.id)}
                >
                  <CategoryDot id={category.id} />
                  {category.name}
                </button>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className={cn('relative flex py-2.5 rounded-xl bg-bg-3 text-base transition-shadow duration-300', {
        'shadow-floating': dragging && !dropping
      })}>
        <div className='relative flex flex-col gap-0.5 justify-center w-5 select-none'>
          <div
            ref={dragGrip}
            title={dragging ? undefined : `${runtimeEnv.touch ? 'Tap' : 'Click'} and Hold to Drag Product`}
            className='z-10 absolute -inset-2.5 cursor-grab'
            onTouchStart={event => onDragGripPress(event as any)}
            onMouseDown={event => onDragGripPress(event as any)}
            onTouchMove={onDragGripTouchMoveOrEnd}
            onTouchEnd={onDragGripTouchMoveOrEnd}
          />
          <div className='flex justify-center items-center gap-0.5'>
            <div className='h-1 aspect-square rounded-xs bg-bg-4' />
            <div className='h-1 aspect-square rounded-xs bg-bg-4' />
          </div>
          <div className='flex justify-center items-center gap-0.5'>
            <div className='h-1 aspect-square rounded-xs bg-bg-4' />
            <div className='h-1 aspect-square rounded-xs bg-bg-4' />
          </div>
          <div className='flex justify-center items-center gap-0.5'>
            <div className='h-1 aspect-square rounded-xs bg-bg-4' />
            <div className='h-1 aspect-square rounded-xs bg-bg-4' />
          </div>
        </div>
        <div className='relative flex flex-1 rounded-md bg-bg'>
          <div className='text-base p-2.5 w-full whitespace-pre-wrap pointer-events-none invisible'>
            {props.product.name.substring(0, selectionStart)}
            <span ref={textAreaSelection} className='relative'>
              {(() => {
                const selected = props.product.name.substring(selectionStart, selectionEnd);

                // on iOS flipped autocomplete is higher initially without this
                if (!selected && props.product.name.length < 2) {
                  return <>&nbsp;</>;
                }

                return selected;
              })()}
              {showAutocomplete && (
                <div className={cn(
                  'z-menu flex flex-col gap-0.5 p-2.5 rounded-xl select-none pointer-events-auto visible',
                  '[--left-gap:_calc(var(--unit)_*_-2.5)]',
                  '[--right-gap:_calc(var(--unit)_*_5)]',
                  'absolute left-[var(--left-gap)]',
                  'max-w-[calc(100vw_-_var(--left-gap)_-_var(--right-gap)_-_var(--x-offset)_*_1px)]',
                  'bg-floating backdrop-blur-floating backdrop-saturate-floating shadow-floating',
                  textAreaSelection.current
                  &&
                  (document.querySelector('footer')!.getBoundingClientRect().top - textAreaSelection.current.getBoundingClientRect().bottom)
                  <
                  (textAreaSelection.current.getBoundingClientRect().top - document.querySelector('header')!.getBoundingClientRect().bottom)
                  ? 'bottom-8' : 'top-8'
                )} style={{
                  '--x-offset': textAreaSelection.current!.getBoundingClientRect().left
                } as CSSProperties}>
                  {getAutocompleteProducts().map((product, i) => (
                    <button
                      key={product.uuid}
                      className={cn(
                        'flex items-center gap-2.5 p-2.5 rounded-md text-sm',
                        'active:text-fg-max active:bg-bg-3', {
                          'text-fg-max bg-bg-3': (autocompleteIndexBeingPressed === undefined ? autocompleteIndex : autocompleteIndexBeingPressed) === i
                        }
                      )}
                      onTouchStart={() => setAutocompleteIndexBeingPressed(i)}
                      onMouseDown={() => setAutocompleteIndexBeingPressed(i)}
                      onClick={() => {
                        model.replaceDayProduct(date, props.product.position, product.uuid);
                        setAutocompleteIndexBeingPressed(undefined);
                      }}
                    >
                      <CategoryDot id={product.categoryId} />
                      <div className='truncate'>{product.name}</div>
                    </button>
                  ))}
                </div>
              )}
            </span>
            {props.product.name.substring(selectionEnd)}{props.product.name === '' ? '\n' : ''}
          </div>
          <textarea
            ref={textArea}
            className='text-base p-2.5 w-full h-full resize-none absolute bg-transparent'
            placeholder='Name this product…'
            spellCheck={false}
            autoCorrect='off'
            autoCapitalize='off'
            value={props.product.name}
            onChange={event => {
              model.updateDayProductName(date, props.product.position, event.target.value);
              setCanShowAutocomplete(true);
              setAutocompleteIndex(0);
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                textArea.current!.blur();

                if (showAutocomplete) {
                  model.replaceDayProduct(date, props.product.position, getAutocompleteProducts()[autocompleteIndex].uuid);
                }
              } else if (event.key === 'ArrowUp') {
                if (showAutocomplete) {
                  event.preventDefault();

                  setAutocompleteIndex(current => current === 0 ? getAutocompleteProducts().length - 1 : current - 1);
                }
              } else if (event.key === 'ArrowDown') {
                if (showAutocomplete) {
                  event.preventDefault();

                  setAutocompleteIndex(current => current === getAutocompleteProducts().length - 1 ? 0 : current + 1);
                }
              } else if (['Escape', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
                if (showAutocomplete) {
                  setCanShowAutocomplete(false);
                }
              }
            }}
            onFocus={() => {
              setCanShowAutocomplete(false);
              model.focusEditor();
            }}
            onBlur={() => {
              setCanShowAutocomplete(false);
              model.blurEditor();
            }}
          />
        </div>
        <button title='Like' className='flex justify-center items-center w-14 group' onClick={() => {
          model.toggleDayProductLike(date, props.product.position);

          if (props.product.liked) {
            setLikeFloating(undefined);
          } else {
            setLikeFloating({
              initialTransition: {},
              halfTransition: {},
              fullTransition: {}
            });
          }
        }}>
          <div ref={likeButton} className='relative'>
            <FontAwesomeIcon
              className={cn(
                'shrink-0 h-5 duration-150 will-change-transform group-active:scale-80',
                props.product.liked ? 'text-accent' : 'text-fg-6',
                globalDragState?.dropEnding ? 'transition-transform' : 'transition'
              )}
              icon={faHeart}
            />
            {likeFloating && (
              <div className='z-10 absolute top-0 pointer-events-none'>
                <div className='opacity-0 scale-50' style={{
                  transition: 'transform 4s, opacity 3s',
                  ...likeFloating.initialTransition
                }}>
                  <div style={{
                    transition: 'transform 10s ease-in, opacity 5s 5s',
                    ...likeFloating.fullTransition
                  }}>
                    <div style={{
                      '--translate-x': 0,
                      '--rotate': 0,
                      transform: 'translateX(calc(var(--translate-x) * 1px)) rotate(calc(var(--rotate) * 1deg))',
                      transition: 'transform 5s ease-in-out, opacity 3s',
                      ...likeFloating.halfTransition
                    } as CSSProperties}>
                      <FontAwesomeIcon className='shrink-0 h-5 text-accent' icon={faHeart} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </button>
        <button title='Delete Product' className={cn(
          'flex justify-center items-center w-7 aspect-square border border-border bg-bg text-fg-6 rounded-full',
          'absolute -top-2.5 -right-2.5',
          'transition duration-150 active:scale-90'
        )} onClick={() => {
          model.deleteDayProduct(date, props.product.position, 'Product deleted');
        }}>
          <FontAwesomeIcon className='shrink-0 h-3.5' icon={faTimes} />
        </button>
      </div>
    </div>
  );
});
ProductView.displayName = 'ProductView';
