import { faCalendar, faClone, faCopy, faEllipsisH, faHeart, faPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import toPairs from 'lodash/toPairs';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { createRef, CSSProperties, RefObject, useLayoutEffect, useRef, useState } from 'react';
import { useMenuKey } from '../../hooks/useMenuKey';
import { useOuterDismissKey } from '../../hooks/useOuterDismissKey';
import { browser, useRuntimeEnv } from '../../hooks/useRuntimeEnv';
import { ToastDuration, ToastStyle, useModel } from '../../model';
import { usePage } from '../../page';
import { CategoryDot } from '../CategoryDot';
import { Header } from '../Header';
import styles from './styles.module.css';

export const Top = () => {
  const page = usePage();
  const router = useRouter();

  return (
    <div className='flex flex-col flex-1 p-5'>
      <Header fullWidth overflowVisible>
        <div className='flex flex-1 -mx-5 relative [--easing:cubic-bezier(0.65,0.65,0,1.12)]'>
          <div className='flex items-center px-5 w-full h-6 rounded-full text-fg-4 font-semibold'>
            <Link legacyBehavior href={page.nextHref('/top')} scroll={false}>
              <a className='flex flex-1 justify-center items-center'>
                Products
              </a>
            </Link>
            <Link legacyBehavior href={page.nextHref('/top/bundles')} scroll={false}>
              <a className='flex flex-1 justify-center items-center'>
                Bundles
              </a>
            </Link>
          </div>
          <div className='absolute flex w-full h-full transition-[clip-path] duration-400 ease-[var(--easing)] text-white bg-gradient font-semibold pointer-events-none' style={{
            clipPath: router.pathname === '/top/bundles' ? 'inset(0 calc(var(--unit) * 5) 0 50% round 100px)' : 'inset(0 50% 0 calc(var(--unit) * 5) round 100px)'
          }}>
            <div className='flex flex-1 pl-5 justify-center items-center'>
              Products
            </div>
            <div className='flex flex-1 pr-5 justify-center items-center'>
              Bundles
            </div>
          </div>
        </div>
      </Header>
      {router.pathname === '/top' && <Products />}
      {router.pathname === '/top/bundles' && <Bundles />}
    </div>
  );
};

const Products = () => {
  const page = usePage();
  const router = useRouter();
  const model = useModel();
  const runtimeEnv = useRuntimeEnv();

  const unfilteredItems = toPairs(
    groupBy(
      toPairs(
        groupBy(
          model.data.days
            .flatMap(({ products }) => products)
            .filter(({ name }) => name),
          model.productKey
        )
      )
        .map(([, products]) => ({
          ...products[0],
          likeCount: products.filter(({ liked }) => liked).length
        }))
        .filter(({ likeCount }) => likeCount > 0),
      ({ categoryId }) => categoryId
    )
  )
    .map(([categoryId, products]) => ({
      categoryId: parseInt(categoryId),
      products: orderBy(
        products,
        'likeCount',
        'desc'
      )
    }));
  const itemsCategories = model.data.categories.filter(({ id }) => unfilteredItems.some(({ categoryId }) => categoryId === id));
  const selectedCategoryId = parseInt(router.query.category as string || '0');
  const items = unfilteredItems.filter(({ categoryId }) => !selectedCategoryId || categoryId === selectedCategoryId);
  const itemsProductCount = sumBy(items, ({ products }) => products.length);

  const [categoriesInnerWidth, setCategoriesInnerWidth] = useState(0);
  const [mergeKey, setMergeKey] = useOuterDismissKey('');
  const [menuKey, setMenuKey] = useMenuKey('');
  const menuButtonRefs = useRef<RefObject<HTMLButtonElement>[]>([]);
  const menuKeyIndex = items.flatMap(({ products }) => products).findIndex(product => model.productKey(product) === menuKey);

  if (browser) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      setCategoriesInnerWidth(window.innerWidth);
    }, []);
  }

  if (menuButtonRefs.current.length !== itemsProductCount) {
    menuButtonRefs.current = range(0, itemsProductCount).map((_, i) => menuButtonRefs.current[i] || createRef());
  }

  if (items.length === 0) {
    return (
      <div className='flex flex-col flex-1 justify-center items-center gap-2.5 select-none'>
        <div className='flex justify-center items-center rounded-full h-36 aspect-square mb-5 bg-bg-3'>
          <FontAwesomeIcon className='shrink-0 h-8 text-accent' icon={faHeart} />
        </div>

        <div className='text-base text-fg-3'>Give likes to the products in your washes.</div>
        <div className='text-base text-fg-3'>We&apos;ll show your best products here.</div>

        <Link legacyBehavior href={page.nextHref('add/[date]', 'date', model.serializeDate(new Date()))} scroll={false}>
          <a className={cn(
            styles.beacon,
            'flex items-center px-6 h-12 gap-1.5 mt-5 rounded-full text-white bg-gradient text-base font-semibold whitespace-nowrap active:scale-95 transition duration-150'
          )}>
            <FontAwesomeIcon className='shrink-0 h-3.5' icon={faPlus} />Add a wash
          </a>
        </Link>
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-10'>
      {itemsCategories.length > 1 && (
        <div className='grid grid-cols-2 landscape:grid-cols-3 gap-y-5 mx-2.5'>
          <div className='flex'>
            <Link legacyBehavior href={page.nextHref('', 'category')} scroll={false} replace>
              <a className={cn(
                'flex items-center px-2.5 h-6 gap-1.5 rounded-full text-2sm font-semibold leading-none whitespace-nowrap opacity-70',
                'transition duration-150 active:scale-95',
                selectedCategoryId ? 'active:bg-bg-2 active:opacity-100' : 'bg-bg-3 opacity-100'
              )}>
                <div className='h-2 aspect-square rounded-full border-[1px] border-fg' />
                <div>All Products</div>
              </a>
            </Link>
          </div>
          {itemsCategories.map(category => (
            <div key={category.id} className='flex'>
              <Link legacyBehavior href={page.nextHref('', 'category', selectedCategoryId === category.id ? undefined : category.id)} scroll={false} replace>
                <a className={cn(
                  'flex items-center px-2.5 h-6 gap-1.5 rounded-full text-2sm font-semibold leading-none whitespace-nowrap opacity-70',
                  'transition duration-150 active:scale-95',
                  category.id === selectedCategoryId ? 'bg-bg-3 opacity-100' : 'active:bg-bg-2 active:opacity-100'
                )}>
                  <CategoryDot id={category.id} />
                  <div>{category.pluralName}</div>
                </a>
              </Link>
            </div>
          ))}
        </div>
      )}
      <div className='flex flex-col gap-10'>
        {items.map((item, categoryIndex) => (
          <div key={item.categoryId} className='flex flex-col gap-2.5'>
            {item.products.map((product, productIndex) => (
              <div
                key={model.productKey(product)}
                className={cn('flex flex-col', {
                  [styles.wiggle]: mergeKey === model.productKey(product)
                })}
                style={{
                  '--inner-width': categoriesInnerWidth
                } as CSSProperties}
              >
                <div className='flex justify-between gap-5 h-5 ml-1.5 text-2sm font-semibold leading-none'>
                  <div className='flex items-center gap-1.5 relative'>
                    <CategoryDot id={product.categoryId} />
                    <div>{model.data.categories.find(({ id }) => id === product.categoryId)!.name}</div>
                  </div>
                  <div className='flex items-center justify-end gap-1 relative'>
                    {mergeKey ? (
                      <div className='self-end mr-1 mb-0.75 text-4sm font-bold uppercase tracking-widest text-fg-5'>
                        {mergeKey === model.productKey(product) ? 'Duplicate' : `${runtimeEnv.touch ? 'Tap' : 'Click'} to replace with me`}
                      </div>
                    ) : (
                      <button
                        ref={menuButtonRefs.current[sumBy(items.filter((_, i) => i < categoryIndex), ({ products }) => products.length) + productIndex]}
                        title='Actions'
                        className={cn(
                          'px-2.5 h-full rounded-xl',
                          'transition duration-150 active:scale-90'
                        )}
                        onClick={event => {
                          if (menuKey) {
                            event.stopPropagation();
                          }

                          setMenuKey(model.productKey(product) === menuKey ? '' : model.productKey(product));
                        }}
                      >
                        <FontAwesomeIcon className={cn(
                          'shrink-0 h-3',
                          model.productKey(product) === menuKey ? 'fill-gradientSvg' : 'fill-fg-6'
                        )} icon={faEllipsisH} />
                      </button>
                    )}
                    <AnimatePresence>
                      {model.productKey(product) === menuKey && (
                        <motion.div
                          transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
                          initial={{ opacity: 0, scale: 0.95 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.95 }}
                          className={cn(
                            'z-floating flex flex-col gap-1 py-2.5 rounded-xl whitespace-nowrap absolute select-none',
                            'bg-floating backdrop-blur-floating backdrop-saturate-floating shadow-floating',
                            menuKeyIndex >= 0
                            &&
                            (document.querySelector('footer')!.getBoundingClientRect().top - menuButtonRefs.current![menuKeyIndex].current!.getBoundingClientRect().bottom)
                            <
                            (menuButtonRefs.current![menuKeyIndex].current!.getBoundingClientRect().top - document.querySelector('header')!.getBoundingClientRect().bottom)
                            ? 'bottom-5' : 'top-5'
                          )}
                        >
                          <Link legacyBehavior href={page.nextHref(model.productKey(product))} scroll={false}>
                            <a className={cn(
                              'flex items-center h-12 gap-2.5 pl-3 pr-5 mx-2.5 rounded-md text-base',
                              'transition duration-150 active:text-fg-max active:bg-floating-2'
                            )}>
                              <div className='h-4 aspect-square flex justify-center items-center'>
                                <FontAwesomeIcon className='shrink-0 h-3.75 fill-gradientSvg' icon={faCalendar} />
                              </div>
                              Show in Calendar
                            </a>
                          </Link>
                          <button className={cn(
                            'flex items-center h-12 gap-2.5 pl-3 pr-5 mx-2.5 rounded-md text-base',
                            'transition duration-150 active:text-fg-max active:bg-floating-2'
                          )} onClick={() => {
                            model.showToast('Now pick another product from this list to replace it', ToastStyle.Info, ToastDuration.Long);
                            setMergeKey(model.productKey(product));
                          }}>
                            <div className='h-4 aspect-square flex justify-center items-center'>
                              <FontAwesomeIcon className='shrink-0 h-4 fill-gradientSvg' icon={faClone} />
                            </div>
                            This is a Duplicate
                          </button>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
                <button
                  className='flex items-center py-2.5 pl-2.5 rounded-xl bg-bg-3 transition duration-150 [&:active:not(:disabled)]:scale-[0.97]'
                  disabled={!mergeKey || mergeKey === model.productKey(product)}
                  onClick={() => {
                    model.mergeProducts(items.flatMap(({ products }) => products).find(product => mergeKey === model.productKey(product))!.uuid, product.uuid, 'Duplicate replaced');
                    setMergeKey('');
                  }}
                >
                  <div className='flex-1 p-2.5 text-base rounded-md bg-bg'>{product.name}</div>
                  <div className='flex flex-col justify-center items-center w-14 gap-1.5 text-3sm font-bold leading-none text-fg-5'>
                    <div>{product.likeCount}</div>
                    <FontAwesomeIcon className='shrink-0 h-3.5 text-accent' icon={faHeart} />
                  </div>
                </button>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const Bundles = () => {
  const page = usePage();
  const model = useModel();

  const items = orderBy(
    toPairs(
      groupBy(
        model.data.days
          .filter(({ products }) => products.filter(({ name }) => name).length > 1)
          .filter(({ rating }) => rating),
        ({ products }) => model.bundleKey(products)
      )
    )
      .map(([, days]) => ({
        products: sortBy(days[0].products.filter(({ name }) => name), ['categoryId', 'name']),
        rating: sumBy(days, 'rating') / days.length,
        days
      })),
    'rating',
    'desc'
  );

  const [menuKey, setMenuKey] = useMenuKey('');
  const menuButtonRefs = useRef<RefObject<HTMLButtonElement>[]>([]);
  const menuKeyIndex = items.findIndex(({ products }) => model.bundleKey(products) === menuKey);

  if (menuButtonRefs.current.length !== items.length) {
    menuButtonRefs.current = range(0, items.length).map((_, i) => menuButtonRefs.current[i] || createRef());
  }

  if (items.length === 0) {
    return (
      <div className='flex flex-col flex-1 justify-center items-center gap-2.5 select-none'>
        <div className='flex justify-center items-center rounded-full h-36 aspect-square mb-5 bg-bg-3'>
          <FontAwesomeIcon className='shrink-0 h-8 text-accent-3' icon={faStar} />
        </div>

        <div className='text-base text-fg-3'>Rate the bundles in your washes.</div>
        <div className='text-base text-fg-3'>We&apos;ll show your best bundles here.</div>

        <Link legacyBehavior href={page.nextHref('add/[date]', 'date', model.serializeDate(new Date()))} scroll={false}>
          <a className={cn(
            styles.beacon,
            'flex items-center px-6 h-12 gap-1.5 mt-5 rounded-full text-white bg-gradient text-base font-semibold whitespace-nowrap active:scale-95 transition duration-150'
          )}>
            <FontAwesomeIcon className='shrink-0 h-3.5' icon={faPlus} />Add a wash
          </a>
        </Link>
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-15'>
      {items.map((item, i) => (
        <div key={model.bundleKey(item.products)} className='flex flex-col gap-5 rounded-xl'>
          <div className='flex flex-col gap-2.5'>
            <div className='flex justify-center text-3sm font-bold uppercase tracking-widest text-fg-6 select-none'>
              {item.days.length} Wash{item.days.length > 1 ? 'es' : ''}
            </div>
            <div className='flex'>
              <div className='flex-1' />
              <div className='flex items-center gap-4'>
                {range(1, 6).map(value => (
                  <svg key={value} viewBox='0 0 576 512' className='shrink-0 h-7'>
                    <defs>
                      <linearGradient id={`gradient_${i}_${value}`}>
                        <stop stopColor='var(--accent3)' offset={`${Math.floor(Math.min(Math.max(item.rating - value + 1, 0), 1) * 100)}%`} />
                        <stop stopColor='var(--fg6)' offset='0%' />
                      </linearGradient>
                    </defs>
                    <path fill={`url(#gradient_${i}_${value})`} d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z' />
                  </svg>
                ))}
              </div>
              <div className='flex flex-1 items-center justify-end relative'>
                <button
                  ref={menuButtonRefs.current[i]}
                  title='Actions'
                  className={cn(
                    'px-2.5 h-6 rounded-xl',
                    'transition duration-150 active:scale-90', {
                      'bg-bg-2': model.bundleKey(item.products) === menuKey
                    }
                  )}
                  onClick={event => {
                    if (menuKey) {
                      event.stopPropagation();
                    }

                    setMenuKey(model.bundleKey(item.products) === menuKey ? '' : model.bundleKey(item.products));
                  }}
                >
                  <FontAwesomeIcon className={cn(
                    'shrink-0 h-3',
                    model.bundleKey(item.products) === menuKey ? 'fill-gradientSvg' : 'fill-fg-6'
                  )} icon={faEllipsisH} />
                </button>
                <AnimatePresence>
                  {model.bundleKey(item.products) === menuKey && (
                    <motion.div
                      transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
                      initial={{ opacity: 0, scale: 0.95 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.95 }}
                      className={cn(
                        'z-floating flex flex-col gap-1 py-2.5 rounded-xl whitespace-nowrap absolute select-none',
                        'bg-floating backdrop-blur-floating backdrop-saturate-floating shadow-floating',
                        menuKeyIndex >= 0
                        &&
                        (document.querySelector('footer')!.getBoundingClientRect().top - menuButtonRefs.current![menuKeyIndex].current!.getBoundingClientRect().bottom)
                        <
                        (menuButtonRefs.current![menuKeyIndex].current!.getBoundingClientRect().top - document.querySelector('header')!.getBoundingClientRect().bottom)
                        ? 'bottom-11' : 'top-11'
                      )}
                    >
                      <button className={cn(
                        'flex items-center h-12 gap-2.5 pl-3 pr-5 mx-2.5 rounded-md text-base',
                        'transition duration-150 active:text-fg-max active:bg-floating-2'
                      )} onClick={() => {
                        model.copyBundleFromTop(item.products);
                      }}>
                        <div className='h-4 aspect-square flex justify-center items-center'>
                          <FontAwesomeIcon className='shrink-0 h-4 fill-gradientSvg' icon={faCopy} />
                        </div>
                        Copy Bundle
                      </button>
                      <Link legacyBehavior href={page.nextHref(model.bundleKey(item.products))} scroll={false}>
                        <a className={cn(
                          'flex items-center h-12 gap-2.5 pl-3 pr-5 mx-2.5 rounded-md text-base',
                          'transition duration-150 active:text-fg-max active:bg-floating-2'
                        )}>
                          <div className='h-4 aspect-square flex justify-center items-center'>
                            <FontAwesomeIcon className='shrink-0 h-3.5 fill-gradientSvg' icon={faCalendar} />
                          </div>
                          Show in Calendar
                        </a>
                      </Link>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-2.5'>
            {item.products.map(product => (
              <div key={product.uuid} className='flex flex-col'>
                <div className='flex items-center gap-1.5 h-5 ml-1.5 text-2sm font-semibold leading-none'>
                  <CategoryDot id={product.categoryId} />
                  <div>{model.data.categories.find(({ id }) => id === product.categoryId)!.name}</div>
                </div>
                <div className='p-2.5 rounded-xl bg-bg-3'>
                  <div className='p-2.5 rounded-md bg-bg text-base'>
                    {product.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
