import { differenceInMilliseconds, format } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { v4 as newUuid } from 'uuid';

export const maxUndoWaitMs = 5_000;

export interface User {
  email:      string
  firstName:  string
  lastName:   string
  photoUrl:   string
}

export interface BlogPost {
  cmsId:  number
  seen:   boolean
}

export interface Category {
  id:         number
  name:       string
  pluralName: string
}

export interface Product {
  uuid:       string
  position:   number
  name:       string
  categoryId: number
  liked:      boolean
}

export interface Day {
  date:         string
  rating:       number | null
  comment:      string
  salonComment: string
  products:     Product[]
}

export interface Data {
  empty?:       boolean
  lastSaveUuid: string | null
  welcomed:     boolean
  notesText:    string
  user:         User | null
  categories:   Category[]
  blogPosts:    BlogPost[]
  days:         Day[]
}

interface CopiedBundleOrigin {
  date?:    Date
  origin?:  string
}

export interface CopiedBundle extends CopiedBundleOrigin {
  products: Product[]
}

export enum ToastStyle {
  Success,
  Info,
  Error
}

export enum ToastDuration {
  Short,
  Standard,
  Long
}

export interface Toast {
  message:  string
  style:    ToastStyle
  duration: ToastDuration
  undo?:    () => void
}

export enum ModelStatus {
  Offline,
  Syncing,
  Idle
}

interface Model {
  data: Data
  setData: (data: Data) => void
  status: ModelStatus
  synced: boolean
  toast?: Toast
  serializeDate: (date: Date) => string
  productKey: (product: Product) => string
  bundleKey: (products: Product[]) => string;
  showToast: (message: string, style: ToastStyle, duration: ToastDuration) => void
  editorFocused: boolean
  focusEditor: () => void
  blurEditor: () => void
  dragging: boolean
  startDrag: () => void
  endDrag: () => void
  copiedBundle?: CopiedBundle
  copyBundleFromTop: (products: Product[]) => void
  copyBundleFromDate: (date: Date, products: Product[]) => void
  pasteBundle: (date: string) => void
  clearCopiedBundle: () => void
  welcomed: () => void
  seen: (cmsId: number) => void
  editNotesText: (notesText: string) => void
  mergeProducts: (uuid: string, withUuid: string, toastMessage: string) => void
  updateDayComment: (date: string, comment: string) => void
  deleteDayComment: (date: string, toastMessage: string) => void
  updateDaySalonComment: (date: string, salonComment: string) => void
  deleteDaySalonComment: (date: string, toastMessage: string) => void
  toggleDayRating: (date: string, rating: number) => void
  toggleDayProductLike: (date: string, position: number) => void
  updateDayProductName: (date: string, position: number, name: string) => void
  updateDayProductCategory: (date: string, position: number, categoryId: number) => void
  addDayProduct: (date: string) => void
  replaceDayProduct: (date: string, position: number, withUuid: string) => void
  updateDayProductPosition: (date: string, position: number, newPosition: number) => void
  deleteDayProduct: (date: string, position: number, toastMessage: string) => void
  deleteDay: (date: string, toastMessage: string) => void
  getDay: (date: string) => Day | undefined
}

const Context = createContext({} as Model);

export const useModel = () => useContext(Context);

export const ModelProvider = (props: PropsWithChildren<{
  data: Data
}>) => {
  const [data, setData] = useState(props.data);
  const [editorFocused, setEditorFocused] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [copiedBundle, setCopiedBundle] = useState<CopiedBundle>();
  const [toast, setToast] = useState<Toast>();
  const serverQueue = useServerQueue({
    data,
    setData,
    onLogicalError: () => {
      showToast("Oops! Something went wrong. Please try again… 😓", ToastStyle.Error, ToastDuration.Long);
    }
  });
  const status = (() => {
    if (!serverQueue.online) {
      return ModelStatus.Offline;
    }

    if (!serverQueue.empty) {
      return ModelStatus.Syncing;
    }

    return ModelStatus.Idle;
  })();
  const synced = serverQueue.empty;

  const serializeDate = (date: Date) => format(date, 'yyyy-MM-dd');

  const productKey = (product: Product) => `${product.categoryId}:${product.name}`;

  const bundleKey = (products: Product[]) => products.filter(({ name }) => name).map(({ categoryId, name }) => `${categoryId}:${name.trim().toLocaleLowerCase()}`).sort().toString();

  const showToastWithUndo = (message: string, duration: ToastDuration) => {
    const undoData = cloneDeep(data);
    const undoCopiedBundle = cloneDeep(copiedBundle);
    setToast({ message, duration, style: ToastStyle.Success, undo: () => {
      serverQueue.undo();
      showToast('Undoed', ToastStyle.Success, ToastDuration.Short);
      setData(undoData);
      setCopiedBundle(undoCopiedBundle);
    } });
  };

  const showToast = (message: string, style: ToastStyle, duration: ToastDuration) => {
    setToast({ message, style, duration });
  };

  const hideToast = () => {
    setToast(undefined);
  };

  const focusEditor = () => {
    setEditorFocused(true);
  };

  const blurEditor = () => {
    setEditorFocused(false);
  };

  const startDrag = () => {
    setDragging(true);
  };

  const endDrag = () => {
    setDragging(false);
  };

  const copyBundle = (products: Product[], origin: CopiedBundleOrigin) => {
    setCopiedBundle({ ...origin, products });
    showToast(`Copied ${products.length} products`, ToastStyle.Success, ToastDuration.Short);
  };

  const copyBundleFromTop = (products: Product[]) => copyBundle(products, { origin: 'Top' });

  const copyBundleFromDate = (date: Date, products: Product[]) => copyBundle(products, { date });

  const pasteBundle = (date: string) => {
    const sortedProducts = sortBy(copiedBundle!.products, product => product.position);
    const productUuids = sortedProducts.map(({ uuid }) => uuid);
    const newProductUuids = sortedProducts.map(() => newUuid());
    serverQueue.undoableAction({ method: 'POST', url: `/api/days/${date}/paste`, body: { productUuids, newProductUuids } });
    showToastWithUndo(`Pasted ${sortedProducts.length} products`, ToastDuration.Standard);
    setData(current => ({
      ...current,
      days: [
        ...current.days.filter(day => day.date !== date),
        {
          rating: null,
          comment: '',
          salonComment: '',
          ...(current.days.find(day => day.date === date) || {}),
          products: sortedProducts.map((product, i) => ({
            ...product,
            uuid: newProductUuids[i],
            position: i + 1,
            liked: false
          })),
          date
        }
      ]
    }));
    clearCopiedBundle();
  };

  const clearCopiedBundle = () => {
    setCopiedBundle(undefined);
  };

  const welcomed = () => {
    serverQueue.action({ method: 'POST', url: '/api/welcomed' });
    setData(current => ({
      ...current,
      welcomed: true
    }));
  };

  const seen = (cmsId: number) => {
    serverQueue.action({ method: 'POST', url: `/api/blog-posts/${cmsId}/seen` });
    setData(current => ({
      ...current,
      blogPosts: [
        ...current.blogPosts,
        { cmsId, seen: true }
      ]
    }));
  };

  const editNotesText = (notesText: string) => {
    serverQueue.typing({ method: 'PUT', url: '/api/notes-text', body: { text: notesText } });
    hideToast();
    setData(current => ({
      ...current,
      notesText
    }));
  };

  const mergeProducts = (uuid: string, withUuid: string, toastMessage: string) => {
    serverQueue.undoableAction({ method: 'POST', url: `/api/products/${uuid}/merge`, body: { withProductUuid: withUuid } });
    showToastWithUndo(toastMessage, ToastDuration.Long);
    const product = getProductByUuid(uuid);
    const withProduct = getProductByUuid(withUuid);
    setData(current => ({
      ...current,
      days: current.days.map(day => ({
        ...day,
        products: day.products.map(dayProduct => productKey(dayProduct) === productKey(product) ? {
          ...dayProduct,
          categoryId: withProduct.categoryId,
          name: withProduct.name
        } : dayProduct)
      }))
    }));
  };

  const updateDayComment = (date: string, comment: string) => {
    serverQueue.typing({ method: 'PUT', url: `/api/days/${date}/comment`, body: { text: comment } });
    hideToast();
    updateDayCommentData(date, comment);
  };

  const deleteDayComment = (date: string, toastMessage: string) => {
    if (getDay(date)?.comment) {
      serverQueue.undoableAction({ method: 'PUT', url: `/api/days/${date}/comment`, body: { text: '' } });
      showToastWithUndo(toastMessage, ToastDuration.Standard);
      updateDayCommentData(date, '');
    } else {
      hideToast();
    }
  };

  const updateDayCommentData = (date: string, comment: string) => {
    setData(current => ({
      ...current,
      days: [
        ...current.days.filter(day => day.date !== date),
        {
          rating: null,
          salonComment: '',
          products: [],
          ...(current.days.find(day => day.date === date) || {}),
          comment,
          date
        }
      ]
    }));
  };

  const updateDaySalonComment = (date: string, salonComment: string) => {
    serverQueue.typing({ method: 'PUT', url: `/api/days/${date}/salon-comment`, body: { text: salonComment } });
    hideToast();
    updateDaySalonCommentData(date, salonComment);
  };

  const deleteDaySalonComment = (date: string, toastMessage: string) => {
    if (getDay(date)?.salonComment) {
      serverQueue.undoableAction({ method: 'PUT', url: `/api/days/${date}/salon-comment`, body: { text: '' } });  
      showToastWithUndo(toastMessage, ToastDuration.Standard);
      updateDaySalonCommentData(date, '');
    } else {
      hideToast();
    }
  };

  const updateDaySalonCommentData = (date: string, salonComment: string) => {
    setData(current => ({
      ...current,
      days: [
        ...current.days.filter(day => day.date !== date),
        {
          rating: null,
          comment: '',
          products: [],
          ...(current.days.find(day => day.date === date) || {}),
          salonComment,
          date
        }
      ]
    }));
  };

  const toggleDayRating = (date: string, rating: number) => {
    serverQueue.action({ method: 'POST', url: `/api/days/${date}/rate`, body: { value: rating } });
    hideToast();
    setData(current => ({
      ...current,
      days: [
        ...current.days.filter(day => day.date !== date),
        (() => {
          const currentDay = current.days.find(day => day.date === date);
          const day: Day = {
            products: [],
            comment: '',
            salonComment: '',
            ...(currentDay || {}),
            rating,
            date
          };

          if (currentDay?.rating === rating) {
            day.rating = null;
          }

          return day;
        })()
      ]
    }));
  };

  const toggleDayProductLike = (date: string, position: number) => {
    serverQueue.action({ method: 'POST', url: `/api/days/${date}/products/${position}/like` });
    hideToast();
    setData(current => ({
      ...current,
      days: current.days.map(day => day.date === date ? ({
        ...day,
        products: day.products.map(dayProduct => dayProduct.position === position ? ({
          ...dayProduct,
          liked: !dayProduct.liked
        }) : dayProduct)
      }) : day)
    }));
  };

  const updateDayProductName = (date: string, position: number, name: string) => {
    serverQueue.typing({ method: 'PUT', url: `/api/days/${date}/products/${position}/name`, body: { text: name } });
    hideToast();
    setData(current => ({
      ...current,
      days: current.days.map(day => day.date === date ? ({
        ...day,
        products: day.products.map(dayProduct => dayProduct.position === position ? ({
          ...dayProduct,
          name
        }) : dayProduct)
      }) : day)
    }));
  };

  const updateDayProductCategory = (date: string, position: number, categoryId: number) => {
    serverQueue.action({ method: 'PUT', url: `/api/days/${date}/products/${position}/category`, body: { id: categoryId } });
    hideToast();
    setData(current => ({
      ...current,
      days: current.days.map(day => day.date === date ? ({
        ...day,
        products: day.products.map(dayProduct => dayProduct.position === position ? ({
          ...dayProduct,
          categoryId
        }) : dayProduct)
      }) : day)
    }));
  };

  const addDayProduct = (date: string) => {
    const uuid = newUuid();
    const day = data.days.find(day => day.date === date);
    const products = day?.products || [];
    const categoryId = (products.length === 0 ?
      data.categories[2] :
      (data.categories.find(({ id }) => !products.some(({ categoryId }) => id === categoryId)) || data.categories[2])).id;
    serverQueue.action({ method: 'POST', url: `/api/days/${date}/products`, body: { uuid, categoryId } });
    hideToast();
    setData(current => ({
      ...current,
      days: [
        ...current.days.filter(day => day.date !== date),
        {
          rating: null,
          comment: '',
          salonComment: '',
          ...(day || {}),
          products: [
            ...products,
            {
              uuid,
              position: (maxBy(products, ({ position }) => position)?.position || 0) + 1,
              categoryId,
              name: '',
              liked: false
            }
          ],
          date
        }
      ]
    }));
  };

  const replaceDayProduct = (date: string, position: number, withUuid: string) => {
    serverQueue.action({ method: 'POST', url: `/api/days/${date}/products/${position}/replace`, body: { withProductUuid: withUuid } });
    hideToast();
    const withProduct = getProductByUuid(withUuid);
    setData(current => ({
      ...current,
      days: current.days.map(day => day.date === date ? ({
        ...day,
        products: day.products.map(dayProduct => dayProduct.position === position ? ({
          ...dayProduct,
          categoryId: withProduct.categoryId,
          name: withProduct.name
        }) : dayProduct)
      }) : day)
    }));
  };

  const updateDayProductPosition = (date: string, position: number, newPosition: number) => {
    serverQueue.action({ method: 'PUT', url: `/api/days/${date}/products/${position}/position`, body: { newPosition } });
    hideToast();
    setData(current => ({
      ...current,
      days: current.days.map(day => day.date === date ? ({
        ...day,
        products: (() => {
          const products = sortBy(day.products, product => product.position);
          const [ product ] = products.splice(position - 1, 1);
          products.splice(newPosition - 1, 0, product);
          return products.map((product, i) => ({ ...product, position: i + 1 }));
        })()
      }) : day)
    }));
  };

  const deleteDayProduct = (date: string, position: number, toastMessage: string) => {
    if (getDay(date)!.products.find(product => product.position === position)!.name) {
      serverQueue.undoableAction({ method: 'DELETE', url: `/api/days/${date}/products/${position}` });  
      showToastWithUndo(toastMessage, ToastDuration.Standard);
    } else {
      serverQueue.action({ method: 'DELETE', url: `/api/days/${date}/products/${position}` });  
      hideToast();
    }

    setData(current => ({
      ...current,
      days: current.days.map(day => day.date === date ? ({
        ...day,
        products: sortBy(day.products, product => product.position)
          .filter(product => product.position !== position)
          .map((product, i) => ({ ...product, position: i + 1 }))
      }) : day)
    }));
  };

  const deleteDay = (date: string, toastMessage: string) => {
    serverQueue.undoableAction({ method: 'DELETE', url: `/api/days/${date}` });
    showToastWithUndo(toastMessage, ToastDuration.Long);
    setData(current => ({
      ...current,
      days: current.days.filter(day => day.date !== date)
    }));
  };

  const getDay = (date: string) => data.days.find(day => day.date === date);

  const getProductByUuid = (uuid: string) => data.days.flatMap(({ products }) => products).find(product => product.uuid === uuid)!;

  return (
    <Context.Provider value={{
      data,
      setData,
      status,
      synced,
      toast,
      serializeDate,
      productKey,
      bundleKey,
      showToast,
      editorFocused,
      focusEditor,
      blurEditor,
      dragging,
      startDrag,
      endDrag,
      copiedBundle,
      copyBundleFromTop,
      copyBundleFromDate,
      pasteBundle,
      clearCopiedBundle,
      welcomed,
      seen,
      editNotesText,
      mergeProducts,
      updateDayComment,
      deleteDayComment,
      updateDaySalonComment,
      deleteDaySalonComment,
      toggleDayRating,
      toggleDayProductLike,
      updateDayProductName,
      updateDayProductCategory,
      addDayProduct,
      replaceDayProduct,
      updateDayProductPosition,
      deleteDayProduct,
      deleteDay,
      getDay,
    }}>
      {props.children}
    </Context.Provider>
  )
};

enum ServerQueueItemType {
  Action = 'ACTION',
  Typing = 'TYPING',
  FetchData = 'FETCH_DATA'
}

enum ServerQueueItemStatus {
  Pending = 'PENDING',
  Submitting = 'SUBMITTING'
}

interface ServerQueueActionParams {
  url: string
  method: string
  body?: object
}

interface ServerQueueItem {
  uuid: string
  type: ServerQueueItemType
  status: ServerQueueItemStatus
  time: string
  undoable: boolean
  action: ServerQueueActionParams
}

const useServerQueue = (params: {
  data: Data
  setData: Dispatch<SetStateAction<Data>>
  onLogicalError: () => void
}) => {
  const [ items, setItems ] = useState<ServerQueueItem[]>(() => {
    if (typeof window === 'undefined') {
      return [];
    }

    const stored = window.localStorage.getItem('serverQueue');

    if (stored) {
      return JSON.parse(stored);
    }

    return [];
  });
  const dataRef = useRef(params.data);
  dataRef.current = params.data;
  const itemsRef = useRef(items);
  itemsRef.current = items;
  const [ online, setOnline ] = useState(typeof window === 'undefined' || window.navigator.onLine);
  const onlineRef = useRef(online);
  onlineRef.current = online;
  const errorCount = useRef(0);
  const timeoutId = useRef(0);

  useEffect(() => {
    try {
      window.localStorage.setItem('serverQueue', JSON.stringify(items));
    } catch (error) {
      console.error(error);
    }
  }, [ items ]);

  useEffect(() => {
    if (params.data.empty || itemsRef.current.length > 0) {
      fetchAction();
    }
  }, []);

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible' && onlineRef.current) {
        fetchAction();
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, []);

  useEffect(() => {
    const on = () => {
      setOnline(true);
      errorCount.current = 0;
    };

    const off = () => {
      setOnline(false);
      errorCount.current = 0;
    };

    window.addEventListener('online', on);
    window.addEventListener('offline', off);

    return () => {
      window.removeEventListener('online', on);
      window.removeEventListener('offline', off);
    };
  }, []);

  useEffect(() => {
    (async () => {
      while (true) {
        const item = itemsRef.current[0];
        const shouldSubmit = () => {
          if (!onlineRef.current || !item) {
            return false;
          }

          const msPassed = differenceInMilliseconds(new Date(), new Date(item.time));

          if (item.undoable && itemsRef.current.filter(({ undoable }) => undoable).length === 1) {
            return msPassed > maxUndoWaitMs;
          }

          if (item.type === ServerQueueItemType.Typing) {
            return msPassed > 300;
          }

          return true;
        };

        if (shouldSubmit()) {
          setItems(current => {
            const [ first, ...rest ] = current;
            return [ { ...first, status: ServerQueueItemStatus.Submitting }, ...rest ];
          });

          try {
            const response = await fetch(item.action.url, {
              method: item.action.method,
              headers: {
                'x-save-uuid': item.uuid,
                'x-last-save-uuid': dataRef.current.lastSaveUuid || ''
              },
              body: item.action.body ? JSON.stringify(item.action.body) : undefined
            });

            if (!response.ok) {
              throw new Error();
            }

            errorCount.current = 0;

            if (response.headers.has('x-logical-error')) {
              params.setData(await response.json());
              setItems([]);

              params.onLogicalError();
            } else {
              if (item.type === ServerQueueItemType.FetchData) {
                params.setData(await response.json());
              } else {
                params.setData(current => ({ ...current, lastSaveUuid: item.uuid }));
              }

              setItems(current => {
                const [ , ...rest ] = current;
                return rest;
              });
            }
          } catch {
            errorCount.current++;

            setItems(current => {
              const [ first, ...rest ] = current;
              return [ { ...first, status: ServerQueueItemStatus.Pending }, ...rest ];
            });
          }
        }

        await new Promise(resolve => {
          timeoutId.current = window.setTimeout(resolve, Math.min(Math.pow(errorCount.current + 1, 2), 2 * 60 * 3) * 300);
        });
      }
    })();

    return () => window.clearTimeout(timeoutId.current);
  }, []);

  const fetchAction = () => {
    setItems(current => current[current.length - 1]?.type === ServerQueueItemType.FetchData ? current : [ ...current, {
      uuid: newUuid(),
      type: ServerQueueItemType.FetchData,
      status: ServerQueueItemStatus.Pending,
      time: new Date().toISOString(),
      undoable: false,
      action: {
        method: 'GET',
        url: '/api/data'
      }
    } ]);
  };

  const action = (params: ServerQueueActionParams) => {
    setItems(current => [ ...current, {
      uuid: newUuid(),
      type: ServerQueueItemType.Action,
      status: ServerQueueItemStatus.Pending,
      time: new Date().toISOString(),
      undoable: false,
      action: params
    } ]);
  };

  const undoableAction = (params: ServerQueueActionParams) => {
    setItems(current => [ ...current, {
      uuid: newUuid(),
      type: ServerQueueItemType.Action,
      status: ServerQueueItemStatus.Pending,
      time: new Date().toISOString(),
      undoable: true,
      action: params
    } ]);
  };

  const typing = (params: ServerQueueActionParams) => {
    setItems(current => {
      const last = current[current.length - 1];
      const newItem = {
        uuid: newUuid(),
        type: ServerQueueItemType.Typing,
        status: ServerQueueItemStatus.Pending,
        time: new Date().toISOString(),
        undoable: false,
        action: params
      };

      if (last?.action.url === params.url && last?.action.method === params.method && last?.status === ServerQueueItemStatus.Pending) {
        return [ ...current.filter(item => item !== last), newItem ];
      }

      return [ ...current, newItem ];
    });
  };

  const undo = () => {
    setItems(current => {
      const reverseCurrent = [ ...current ].reverse();
      const lastUndoable = reverseCurrent.find(({ undoable }) => undoable);
      return current.filter(item => item !== lastUndoable);
    });
  };

  return {
    empty: items.length === 0,
    online,
    action,
    undoableAction,
    typing,
    undo
  };
};
