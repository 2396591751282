export { getStaticProps } from '../src/server/ssr';
import { Tab } from '../src/tabs';

const Page = () => (
  <>
    <p>In 2022 my life partner, told <a href='https://twitter.com/_mihhail' target='_blank' rel='noreferrer'>me</a>, that pretty much all <em>hair journal</em> apps out there are too complex for an average user. This is the kind of app, where you log the products, that you have used, and what effect they&apos;ve had on your hair. Often hair enthusiasts have to go through dozens of product combinations in order to find the ones, that suit their specific type of hair. Managing the notes about what worked and what didn&apos;t can be a tedious process.</p>
    
    <p>Having an expert by my side, I thought, that we could try to fill this potential void in the market — build a <em>simple</em> hair journal app. In the worst case, my partner would end up being the only user, which is still not too bad, since it would at least make their life easier.</p>

    <p>That said, being on-call and paying for a constantly running <em>SaaS on the side</em> is not something I was looking forward to. Thus my goal was to find a serverless stack with reasonably limited free plan.</p>

    <p>As the hair washing process is carried out in a bathroom, naturally, the client had to be a mobile app. Here I also did not want to use the maintenance-intensive native tech, so web seemed like the only reasonable choice.</p>

    <p><strong>Progressive Web App on a freemium serverless stack</strong> it is then.</p>

    <p>Oh, and by the way, you probably have guessed it already — you&apos;re reading this article inside the app that I&apos;ve eventually built. It has a section where users can read articles. In these articles my partner shares their expertise on various topics related to hair treatment. Hopefully, with time, this could grow into a channel, that brings new users from Google.</p>

    <h2>Stack</h2>

    <p>Here is the stack, that I ended up choosing:</p>

    <p>(mostly <em>borrowed</em> from <a href='https://brianlovin.com/writing/how-my-website-works' target='_blank' rel='noreferrer'>Brian Lovin</a> 😊)</p>

    <ul>
      <li>
        <a href='https://vercel.com' target='_blank' rel='noreferrer'>Vercel</a>
        <ul>
          <li><a href='https://vercel.com/docs/concepts/next.js' target='_blank' rel='noreferrer'>Next.js</a> <a href='https://web.dev/what-are-pwas/' target='_blank' rel='noreferrer'>PWA</a> with <a href='https://tailwindcss.com' target='_blank' rel='noreferrer'>Tailwind CSS</a> for front-end</li>
          <li><a href='https://vercel.com/docs/concepts/functions/serverless-functions' target='_blank' rel='noreferrer'>Serverless Functions</a> with <a href='https://www.prisma.io' target='_blank' rel='noreferrer'>Prisma ORM</a> for back-end</li>
        </ul>
      </li>
      <li><a href='https://planetscale.com' target='_blank' rel='noreferrer'>PlanetScale</a> for SQL database (MySQL-compatible)</li>
      <li><a href='https://sentry.io' target='_blank' rel='noreferrer'>Sentry</a> for reporting errors in production</li>
    </ul>

    <p>All of these can be used for free with reasonable limits. Your app would have to get decent scale before you will find yourself needing to pay for infrastructure.</p>

    <p><strong>Setry&apos;s</strong> limits are probably the easiest to hit. That said, your app will still work fine without monitoring. Not so much without back-end or a database.</p>

    <p>With <strong>Vercel</strong>, the main limitation is the number of functions you can deploy, so if you have many endpoints, you may eventually hit it. Finally, be aware, that you cannot use <strong>Vercel&apos;s</strong> free plan with a paid app.</p>

    <p>The main point, however, is that the whole stack is serverless — you don&apos;t have to worry about scaling, uptime and updates. It&apos;s perfect for indie devs like me, who need a cheap, stable infra to run side-projects.</p>

    <h2>User Experience (UX)</h2>

    <p>I think, first it makes sense to lay out what kind of UX I wanted to achieve. My partner was the brains behind what needed to be built, but the rest I had to figure out on my own.</p>
    
    <p>I really wanted to use this project to test my skills in crafting a really sleek and pleasant user experience, taking full advantage of the additional things, that <strong>PWA</strong>+<strong>Next.js</strong>+<strong>Vercel</strong> combo unlocks compared to regular web apps.</p>

    <p>Here is the list of random UX-related thoughts and ideas, that I&apos;ve assembled for myself:</p>

    <ul>
      <li>Bottom tab navigation with back button on top</li>
      <li>App navigation via URL-routing (browser back/forward button/swipe support)</li>
      <li>Single scrollable body (to not break things like <em>tap-the-status-bar-to-scroll-to-the-top</em>)</li>
      <li>Saving scroll positions</li>
      <li>Zero friction between anonymous and signed in modes</li>
      <li>Fast, fun, playful interactions</li>
      <li>Undoes instead of <em>are you sure</em> modals</li>
      <li>Add to home screen</li>
      <li>Splash screens</li>
      <li>Dark mode</li>
      <li>Offline mode</li>
      <li>Server-side rendering of React components</li>
      <li>Fast, CDN-ified blog right within the app (the one you are on)</li>
    </ul>

    <p>Some are concrete <em>technical features</em>, some are more general guidelines, that I tried to incorporate into the UX while implementing.</p>

    <p>Next, let&apos;s expand on how the whole thing works, starting with the web app.</p>

    <h2>Web App</h2>

    <p>The front-end is a regular <strong>React</strong> app with <strong>Server-Side Rendering (SSR)</strong> and routing through <strong>Next.js</strong>.</p>

    <h3>Progressive Web App (PWA)</h3>

    <p>PWA capabilities are added with <a href='https://github.com/shadowwalker/next-pwa' target='_blank' rel='noreferrer'>next-pwa</a> package plus a bunch of meta tags and <code>manifest.json</code>. PWA&apos;s are hybrids between native apps and web apps (web pages in your browser). They can be added to the home screen, show splash screens and cache static resources. Caching enables resources to be loaded from the device in case there is no network connection (offline mode).</p>

    <p>For a true offline mode, however, just caching HTML, JavaScript and CSS is not enough. You need an actual way to store and process user actions locally, then sync them to the server, when network becomes available. More on that later…</p>

    <h3>Navigation Model</h3>
    
    <p>Seamless navigation was one of my main goals with <span data-branded>hey.hair</span>. The web app has to feel natural both as a website and as an app.</p>

    <h4>URL Navigation</h4>

    <p>This meant giving every page a URL, that would function as the sole way of reaching this page. URL navigation ensures, that back/forward buttons/swipes work out-of-the-box, and, that you could always get back to the exact place within the app, if you refresh the page.</p>
    
    <p>The latter being particularly important on Safari with its <em>pull-to-refresh</em>, that is quite easy to trigger by accident.</p>

    <p>Another detail worth pointing out is that, thanks to SSR, your refreshed page would load fully-rendered right away, instead of flickering through the <em>loading</em> (or empty) state for a brief moment.</p>

    <h4>Scroll Restoration</h4>

    <p>In addition to the navigation model described above, the app also needs to remember scroll positions of each tab, since inactive tabs are removed from the DOM tree.</p>

    <p>You might be asking yourself — why not just move the active tab on top of inactive ones? In this case all tabs would remain in the DOM, persisting their scroll positions.</p>

    <p>The downside is that you would have to do the scrolling via <code>overflow: scroll</code> inside each tab as opposed to global scroll on the root element.</p>
  
    <p>The differences between the two approaches are subtle, but noticeable. Keeping the scroll on the root element ensures a nicer interplay with native browser interactions (nav bar collapse, scroll to top), which contributes to an overall nicer UX.</p>

    <p>Taking the idea one step further, I was able to emulate another native behaviour — going back within a tab returns to the last scroll position. To accomplish this, I remember scroll positions of the whole navigation stack within every tab.</p>

    <h3>Dark Mode</h3>

    <p>Adding Dark Mode was as simple as defining CSS variables inside <code>@media (prefers-color-scheme)</code> blocks, then mapping these variables to custom <strong>Tailwind</strong> colors. So instead of specifying colors like <code>text-gray-600</code> you would write <code>text-fg-3</code>.</p>

    <p>As for aesthetics, I went for a more translucent vibe with <em>Apple-esque</em> vibrant blurs.</p>

    <h2>Back-End and In-Between Logic</h2>

    <p>Back-end consists of <strong>Vercel Serverless Functions</strong> running on top of <strong>PlanetPlanet</strong> SQL database through <strong>Prisma</strong>, monitored by <strong>Sentry</strong>.</p>

    <h3>Syncing</h3>

    <p>To make the app both snappy and offline-capable, all user actions are processed locally, data stored in-memory (in a <strong>React</strong> state variable). A queue is used to then sync changes one-by-one to the server.</p>

    <p>Data is fetched from the server on startup, and refreshed when app moves to foreground (to account for changes from other devices, that might have happened in the mean time).</p>

    <p>A forced refresh also happens, if the app detects concurrent usage on multiple devices. It does that by comparing the locally stored <em>last action UUID</em> with the one returned from the server.</p>
  
    <p>This UUID is also handy in case the app tries to sync an already synced action. In this case the action is discarded, since the UUID is marked as <em>processed</em> in the database. <a href='https://en.wikipedia.org/wiki/Idempotence' target='_blank' rel='noreferrer'>Idempotency</a> is the official term for this behaviour.</p>

    <h3>Offline Mode</h3>

    <p>The aforementioned queue stores all actions locally, in local storage, until they are synced to the back-end. If network drops, the app just pauses syncing, and keeps processing mutations locally.</p>

    <p>The locally processed data, is, however, stored only in memory. This is done to avoid having to migrate (in future versions of the app) two persistent schemas at the same time — one in the client and one in the database. And, oh boy… you don&apos;t want to be migrating and tracking compatibility in client schemas…</p>

    <p>So what happens, if you reload the app without an internet connection?</p>
    
    <p>In the browser — the page won&apos;t load. That&apos;s easy.</p>

    <p>In PWA mode (app is added to home screen) — static resources are loaded from cache, in-memory data is populated from the last server fetch (so it is older), and the queue has a bunch of unsynced actions. The app cannot continue in this state, since new changes would be applied to old local data, and the server state (defined by the queue) would get out of sync with local data. In this case I just ask the user to find a network connection before continuing. Once the queue syncs to server, I re-fetch the data, and unblock the user. Not the nicest UX, but it will have to do for now.</p>

    <h3>Undoes</h3>

    <p>For this project I wanted to experiment with replacing <em>are you sure</em> prompts with undo actions. The logic here is, that, by removing one extra click (or tap), you can make the UX more streamlined. In the rare case, that the user actually did not want to perform the harmful action, she can press <em>Undo</em>.</p>

    <p>Every important mutating action in the app thus has an undo button, that appears in the floating toast at the top. Undoable actions have a syncing delay, so, if the user decides to undo them, the undo itself is performed only locally, where it is just a matter of replacing the entire <strong>React</strong> state with previous one. The server call in this case is never made, because it is delayed for the duration of the toast.</p>

    <h3>Frictionless Sign-In</h3>

    <p>Users don&apos;t like to be forced to sign in, so (UX-wise) it made sense for me to make the app fully functional in both anonymous and signed in modes, with the most seamless transition between the two.</p>

    <p>Technically, every user gets a 10-year (😅) cookie on first load. This cookie identifies their session on the current device. Signing in attaches your session to an email, allowing you to <em>claim</em> your data and share it between devices.</p>

    <p>One down side for this frictionless setup is that, for anonymous users, clearing browser data effectively means loosing all your <span data-branded>hey.hair</span> data…</p>

    <h3>In-App Blog</h3>

    <p>A cool features of <strong>Next.js</strong> is the ability to choose, per page, whether it is just a static HTML page, that loads data lazily, or a server-rendered page, that pre-loads data and pre-renders the HTML.</p>

    <p>The former approach is best suited for static content like a blog or a public website. It allows <strong>Vercel</strong> to serve these pages from a <a href='https://vercel.com/docs/concepts/edge-network/overview' target='_blank' rel='noreferrer'>CDN</a>, improving the loading speed, which in turn positively affects search rankings.</p>

    <p>This blog post is also served statically by <strong>Vercel Edge Network</strong>. And if you landed here from Google, then it means, that the search rankings are not too bad. 🤓</p>

    <h2>Questions?</h2>

    <p><a href='https://twitter.com/_mihhail' target='_blank' rel='noreferrer'>My DM&apos;s</a> are open.</p>
  </>
);
Page.slug = '/tech';
Page.title = 'Building a mobile PWA on Vercel and PlanetScale';
Page.description = 'Building a touch-oriented PWA using Vercel, Next.js, PlanetScale, Tailwind CSS, Framer Motion and Sentry.';
Page.created = '2022-06-06';
Page.updated = '2022-06-08';
Page.cmsId = 3;
Page.tab = Tab.Ideas;
Page.level = 2;
export default Page;
