import { useEffect, useState } from 'react';
import { useForceRender } from './useForceRender';

export const useMenuKey = <T>(initialValue: T) => {
  const render = useForceRender();
  const state = useState(initialValue);
  const [ nextRender, setNextRender ] = useState(initialValue);

  useEffect(() => {
    setNextRender(state[0]);
  }, [ state[0] ]);

  useEffect(() => {
    if (nextRender) {
      render();

      window.addEventListener('scroll', render);

      return () => window.removeEventListener('scroll', render);
    }
  }, [ nextRender ]);

  useEffect(() => {
    if (nextRender) {
      render();

      window.addEventListener('resize', render);

      return () => window.removeEventListener('resize', render);
    }
  }, [ nextRender ]);

  useEffect(() => {
    if (nextRender) {
      const closeMenu = () => state[1](initialValue);

      window.addEventListener('click', closeMenu);

      return () => window.removeEventListener('click', closeMenu);
    }
  }, [ nextRender ]);

  return state;
};
