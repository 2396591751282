import { useEffect } from 'react';
import { useForceRender } from './useForceRender';

export const useForceRenderOnScroll = () => {
  const render = useForceRender();

  useEffect(() => {
    render();

    window.addEventListener('scroll', render);

    return () => window.removeEventListener('scroll', render);
  }, []);
};
