import { useEffect, useState } from 'react';

export const useOuterDismissKey = <T>(initialValue?: T) => {
  const state = useState(initialValue);
  const [ nextRender, setNextRender ] = useState(initialValue);

  useEffect(() => {
    setNextRender(state[0]);
  }, [ state[0] ]);

  useEffect(() => {
    if (nextRender) {
      const closeMenu = () => {
        state[1](initialValue);
      };

      window.addEventListener('click', closeMenu);

      return () => {
        window.removeEventListener('click', closeMenu);
      }
    }
  }, [ nextRender ]);

  return state;
};
