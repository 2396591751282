import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft, faComment, faCut, faInfo, faLocationCrosshairs, faSignInAlt, faSignOutAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { addDays, addMonths, differenceInMonths, format, getDay, getDaysInMonth, isSameMonth, isThisMonth, isToday, isWeekend, parseISO, startOfMonth, startOfWeek } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import reverse from 'lodash/reverse';
import uniq from 'lodash/uniq';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { createRef, RefObject, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { UrlObject } from 'url';
import { useForceRenderPeriodically } from '../../hooks/useForceRenderPeriodically';
import { useOuterDismissKey } from '../../hooks/useOuterDismissKey';
import { browser, ios, useRuntimeEnv } from '../../hooks/useRuntimeEnv';
import { useTimeout } from '../../hooks/useTimeout';
import { useModel } from '../../model';
import { usePage } from '../../page';
import { useScrollRestore } from '../../scrollRestore';
import { CategoryDot } from '../CategoryDot';
import { Header, HeaderButton } from '../Header';

const startOffset = -3;
const endOffset = 3;
const monthIncrement = 1;

export const Calendar = () => {
  useForceRenderPeriodically();

  const page = usePage();
  const router = useRouter();
  const model = useModel();
  const scrollRestore = useScrollRestore();
  const runtimeEnv = useRuntimeEnv();
  const timeout = useTimeout();
  const productKey = router.query.productKey as string;
  const bundleKey = router.query.bundleKey as string;
  const [userMenu, setUserMenu] = useOuterDismissKey(false);
  const days = (() => {
    if (productKey) {
      return model.data.days.filter(({ products }) => products.some(product => model.productKey(product) === productKey));
    } else if (bundleKey) {
      return model.data.days.filter(({ products }) => model.bundleKey(products) === bundleKey);
    }

    return model.data.days;
  })();
  const focusedMonth = startOfMonth((productKey || bundleKey) && days && days.length > 0 ? parseISO(orderBy(days, 'date', 'desc')[0].date) : new Date());
  const startMonth = router.query.startMonth ? parseISO(router.query.startMonth as string) : addMonths(focusedMonth, startOffset);
  const endMonth = router.query.endMonth ? parseISO(router.query.endMonth as string) : addMonths(focusedMonth, endOffset);
  const months = range(0, differenceInMonths(endMonth, startMonth) + 1).map(i => addMonths(startMonth, i));
  const monthRefs = useRef<RefObject<HTMLDivElement>[]>([]);
  const [scrollToFocusedMonthRetry, setScrollToFocusedMonthRetry] = useState('');
  const startMonthCheckFirstRender = useRef(true);
  const resettingOffsets = useRef(false);
  const [invisible, setInvisible] = useState(scrollRestore.empty);
  const [transitionInvisible, setTransitionInvisible] = useState(false);

  if (monthRefs.current.length !== months.length) {
    monthRefs.current = range(0, months.length).map((_, i) => monthRefs.current[i] || createRef());
  }

  const scrollToMonth = (index: number) => {
    window.scrollBy({
      top:
        monthRefs.current[index].current!.getBoundingClientRect().top
        -
        document.querySelector('header')!.getBoundingClientRect().bottom
        -
        parseFloat(window.getComputedStyle(document.documentElement).getPropertyValue('--unit')) * 5
        *
        parseFloat(getComputedStyle(document.documentElement).fontSize)
    });
  };

  const scrollToFocusedMonthIfNoRestore = () => {
    if (!scrollRestore.hasForPath) {
      scrollToMonth(months.findIndex(month => isSameMonth(month, focusedMonth)));
    }
  };

  const tryResetMonthOffsets = () => {
    if (months.length > Math.abs(startOffset) + endOffset + 1) {
      const focusedMonthRect = monthRefs.current[months.findIndex(month => isSameMonth(month, focusedMonth))].current!.getBoundingClientRect();
      const focusedMonthMiddle = focusedMonthRect.top + focusedMonthRect.height / 2;
      const heightWindow = 0.3;

      if (focusedMonthMiddle > window.innerHeight * heightWindow && focusedMonthMiddle < window.innerHeight * (1 - heightWindow)) {
        const newQuery = { ...router.query };

        delete newQuery.startMonth;
        delete newQuery.endMonth;

        resettingOffsets.current = !!router.query.startMonth;
        router.replace({
          pathname: router.pathname,
          query: newQuery
        }, undefined, { scroll: false, shallow: true });
      }
    }
  };

  if (browser) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(scrollToFocusedMonthIfNoRestore, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      if (ios && !startMonthCheckFirstRender.current) {
        if (resettingOffsets.current) {
          scrollToMonth(months.findIndex(month => isSameMonth(month, focusedMonth)));
        } else {
          scrollToMonth(monthIncrement);
        }
      }

      resettingOffsets.current = false;
      startMonthCheckFirstRender.current = false;
    }, [startMonth.getMonth()]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      if (invisible && ios) {
        setTransitionInvisible(true);
        timeout.set('ios.show.1', 300, () => {
          setInvisible(false);
          timeout.set('ios.show.2', 500, () => setTransitionInvisible(false));
        });
      } else {
        setInvisible(false);
      }
    }, []);
  }

  if (ios) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(tryResetMonthOffsets, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (scrollToFocusedMonthRetry === '') {
        timeout.set('ios.retry', 0, () => setScrollToFocusedMonthRetry('yes'));
      } else if (scrollToFocusedMonthRetry === 'yes') {
        scrollToFocusedMonthIfNoRestore();
        setScrollToFocusedMonthRetry('done');
      }
    }, [ scrollToFocusedMonthRetry ]);
  }

  useEffect(() => {
    const onScroll = () => {
      if (router.asPath.split('?')[0] === window.location.pathname) {
        const triggerPosition = 0.2;

        if ((document.documentElement.scrollTop + document.documentElement.clientHeight) / document.documentElement.scrollHeight > (1 - triggerPosition)) {
          router.replace({
            pathname: router.pathname,
            query: {
              ...router.query,
              endMonth: format(addMonths(endMonth, monthIncrement), 'yyyy-MM')
            }
          }, undefined, { scroll: false, shallow: true });
        } else if (ios ? document.documentElement.scrollTop <= 0 : document.documentElement.scrollTop / document.documentElement.scrollHeight < triggerPosition) {
          router.replace({
            pathname: router.pathname,
            query: {
              ...router.query,
              startMonth: format(addMonths(startMonth, -monthIncrement), 'yyyy-MM')
            }
          }, undefined, { scroll: false, shallow: true });
        } else if (!ios || !router.query.startMonth) {
          tryResetMonthOffsets();
        }
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [router, startMonth.getMonth(), endMonth.getMonth()]);

  return (
    <div className='flex flex-col gap-12 px-2.5 py-5'>
      <Header
        left={router.pathname === '/' ? (
          model.data.user ? (
            <button className='flex items-center gap-1' onClick={() => setUserMenu(true)}>
              <img className='h-5 aspect-square rounded-full' src={model.data.user.photoUrl} />
              <div className='truncate'>{model.data.user.firstName}</div>
            </button>
          ) : (
            <HeaderButton icon={faSignInAlt} selected={userMenu} onClick={() => setUserMenu(true)}>
              Sign In
            </HeaderButton>
          )
        ) : <HeaderButton icon={faArrowLeft} tooltip='Back' href={page.backHref([ 'startMonth', 'endMonth' ])} />}
        leftNotHeaderButton={router.pathname === '/' && !!model.data.user}
        right={(
          <HeaderButton icon={faLocationCrosshairs} onClick={() => {
            scrollToMonth(months.findIndex(month => isSameMonth(month, focusedMonth)));
          }}>
            {router.pathname === '/' ? 'Today' : 'First'}
          </HeaderButton>
        )}
      >
      </Header>
      {model.data.welcomed && model.data.days.length === 0 && (
        <div className={cn(
          'z-cta flex fixed',
          'left-[calc(var(--unit)_*_5_+_var(--content-offset))]',
          'right-[calc(var(--unit)_*_5_+_var(--content-offset))]',
          'bottom-[calc(var(--footer-height)_+_var(--unit)_*_5)]', {
            'transition-opacity duration-300': transitionInvisible,
            'opacity-0 pointer-events-none': invisible
          }
        )}>
          <div className={cn(
            'flex items-center gap-5 p-5 rounded-xl',
            'text-base text-fg-3',
            'bg-floating backdrop-blur-floating backdrop-saturate-floating shadow-floating'
          )}>
            <div className='flex justify-center items-center h-6 aspect-square rounded-full text-white bg-gradient'>
              <FontAwesomeIcon className='h-2.5' icon={faInfo} />
            </div>
            <div>{runtimeEnv.touch ? 'Tap' : 'Click'} on the date of your next planned wash and fill out the details.</div>
          </div>
        </div>
      )}
      <AnimatePresence>
        {userMenu && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className={cn(
              'z-menu flex flex-col gap-1 py-2.5 rounded-xl fixed whitespace-nowrap select-none',
              'top-[calc(var(--header-height)_-_var(--unit))]',
              'left-[calc(var(--unit)_*_2_+_var(--content-offset))]',
              'bg-floating backdrop-blur-floating backdrop-saturate-floating shadow-floating'
            )}
          >
            {model.data.user ? (
              <>
                <div className='flex flex-col items-center gap-2.5 p-5'>
                  <img className='h-12 aspect-square rounded-full' src={model.data.user!.photoUrl} />
                  <div className='text-base font-bold text-fg-5'>{model.data.user!.firstName} {model.data.user!.lastName}</div>
                </div>
                <div className='shrink-0 mx-2.5 my-1.5 h-border bg-border' />
                <button
                  className={cn(
                    'flex items-center h-12 gap-2.5 pl-3 pr-5 mx-2.5 rounded-md text-base',
                    'transition duration-150 active:text-fg-max active:bg-floating-2'
                  )}
                  onClick={async () => {
                    await fetch('/api/logout');
                    window.location.href = '/';
                  }}
                >
                  <div className='h-4 aspect-square flex justify-center items-center'>
                    <FontAwesomeIcon className='shrink-0 h-4 fill-gradientSvg' icon={faSignOutAlt} />
                  </div>
                  Sign Out
                </button>
              </>
            ) : (
              <>
                <Link
                  className={cn(
                    'flex items-center h-12 gap-2.5 pl-3 pr-5 mx-2.5 rounded-md text-base',
                    'transition duration-150 active:text-fg-max active:bg-floating-2'
                  )}
                  href='/api/google'
                >
                  <div className='h-4 aspect-square flex justify-center items-center'>
                    <FontAwesomeIcon className='shrink-0 h-4 fill-gradientSvg' icon={faGoogle} />
                  </div>
                  Sign in with Google
                </Link>
                <div className='shrink-0 mx-2.5 my-1.5 h-border bg-border' />
                <div className='text-2sm text-fg-4 mx-5 mb-2.5 mt-2 w-[182px] whitespace-normal text-justify'>
                  Sign in with your Google account to use the app on multiple devices. For example, on your laptop and your phone.
                </div>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {useMemo(() => months.map((month, i) => (
        <div key={month.toISOString()} ref={monthRefs.current[i]} className={cn('flex flex-col gap-5 select-none', {
          'transition-opacity duration-300': transitionInvisible,
          'opacity-0 pointer-events-none': invisible
        })}>
          <div className='flex items-center gap-2.5 pl-2.5 text-4lg leading-none'>
            {isThisMonth(month) && <div className='h-2 aspect-square rounded-full bg-gradient' />}
            <div className='font-semibold'>{format(month, 'MMMM')}</div>
            <div className='font-normal text-fg-5'>{format(month, 'yyyy')}</div>
          </div>
          <div className='shrink-0 h-border bg-border' />
          <div className='flex'>
            {range(0, 7).map(i => (
              <div key={i} className='flex flex-1 justify-center text-3sm font-semibold tracking-widest uppercase text-fg-5'>
                {format(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), i), 'EEE')}
              </div>
            ))}
          </div>
          <div className='grid grid-cols-7 auto-rows-[calc(var(--unit)_*_15)]'>
            {[
              ...reverse(range(1, getDay(month) || 7)).map(day => addDays(month, -day)),
              ...range(0, getDaysInMonth(month)).map(day => addDays(month, day))
            ].map(date => (
              <DateCell key={date.toISOString()} productKey={productKey} bundleKey={bundleKey} month={month} date={date} href={page.nextHref('day/[date]', 'date', model.serializeDate(date))} />
            ))}
          </div>
        </div>
      )), [productKey, bundleKey, model.data, months.length, invisible])}
    </div>
  );
};

const DateCell = (props: {
  productKey?: string
  bundleKey?: string
  month: Date
  date: Date
  href: UrlObject
}) => {
  const model = useModel();
  const day = model.getDay(model.serializeDate(props.date));

  return (
    <Link legacyBehavior href={props.href} scroll={false}>
      <a
        className={cn(
          'relative flex flex-col justify-center items-center gap-2 text-sm font-semibold rounded-xl',
          'transition duration-150 active:scale-[0.92] active:bg-bg-2', 
          (() => {
            if (isToday(props.date)) {
              return 'font-extrabold';
            }

            if (isWeekend(props.date)) {
              return 'text-fg-4';
            }

            return '';
          })(), {
            'invisible': !isSameMonth(props.date, props.month),
            'active:text-fg-max': !isToday(props.date),
            'opacity-20 dark:opacity-[0.13]':
              props.productKey && !(day?.products || []).some(product => model.productKey(product) === props.productKey)
              ||
              props.bundleKey && model.bundleKey(day?.products || []) !== props.bundleKey
          }
        )}
      >
        {(day?.comment || day?.salonComment) && <FontAwesomeIcon className='shrink-0 top-3.5 absolute h-7 text-[color:theme(colors.stone.200/60%)] dark:text-[color:theme(colors.stone.700/70%)]' icon={faComment} />}
        <div className='flex justify-center gap-0.25 z-10'>
          {range(1, 6).map(value => (
            <FontAwesomeIcon
              key={value}
              className={cn('shrink-0 h-1.25 text-accent-3', {
                'invisible': !day?.rating,
                'hidden': (day?.rating || 5) < value
              })}
              icon={faStar}
            />
          ))}
        </div>
        <div className={cn('leading-none z-10', {
          'text-gradient': isToday(props.date)
        })}>
          {format(props.date, 'd')}
        </div>
        {day?.salonComment ? (
          <FontAwesomeIcon className='shrink-0 h-2.75 fill-gradientSvg' icon={faCut} />
        ) : (
          <div className='flex flex-col gap-0.75 z-10'>
            {(() => {
              const categories = uniq((day?.products || []).filter(({ name }) => name).map(({ categoryId }) => categoryId)).sort();

              const row = (categories: number[], row: number) => (
                <div className='flex justify-center gap-0.75 h-1'>
                  {categories.slice(row * 4, (row + 1) * 4).map(categoryId => <CategoryDot key={categoryId} id={categoryId} small />)}
                </div>
              );

              return (
                <>
                  {row(categories, 0)}
                  {row(categories, 1)}
                </>
              );
            })()}
          </div>
        )}
      </a>
    </Link>
  );
};
