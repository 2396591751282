import cn from 'classnames';
import { useEffect, useState } from 'react';
import { ModelStatus, useModel } from '../model';

export const WaitingOverlay = () => {
  const model = useModel();
  const [ visible, setVisible ] = useState(false);
  const [ status, setStatus ] = useState(ModelStatus.Idle);

  useEffect(() => {
    if (!model.synced) {
      setVisible(true);
    }
  }, []);

  useEffect(() => {
    if (model.synced) {
      setVisible(false);
    }
  }, [ model.synced ]);

  useEffect(() => {
    setStatus(model.status);
  }, [ model.status ]);

  return (
    <div className={cn(
      'z-waitingOverlay flex justify-center items-center select-none fixed inset-0',
      visible ? 'backdrop-blur-md backdrop-saturate-200' : 'transition backdrop-blur-none backdrop-saturate-100 duration-1000 ease-backdrop pointer-events-none'
    )}>
      <div className={cn('flex text-base font-semibold text-fg rounded-xl',
        visible ? '' : 'opacity-0 transition duration-1000 ease-backdrop'
      )}>
        {status === ModelStatus.Syncing && <>Syncing, one moment please… 😴</>}
        {status === ModelStatus.Offline && <>Need internet to continue… 🥺</>}
        {status === ModelStatus.Idle && <>All good now! 😇</>}
      </div>
    </div>
  );
};
