import { useEffect } from 'react';
import { useForceRender } from './useForceRender';

export const useForceRenderPeriodically = () => {
  const render = useForceRender();

  useEffect(() => {
    const timeoutId = setInterval(render, 60_000);

    return () => clearInterval(timeoutId);
  }, []);
};
